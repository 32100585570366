import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { calculateTotal } from '../utils/price';
import { useCardPaymentStore, useCartStore, usePaymentStore } from '../stores';
import logo from '../assets/logo_text_white.png';
import isColorDark from '../utils/isColorDark';
import DiscountTypes from '../constants/discounts';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Lato", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      border: '2px solid red',
      '::placeholder': {
        color: '#6c757d',
      },
    },
    invalid: {
      color: '#dc3545',
      iconColor: '#dc3545',
    },
  },
};

const CardForm = observer(({ renderCart, payButtonColor, payMethod }) => {
  const cardPaymentStore = useCardPaymentStore();
  const paymentStore = usePaymentStore();
  const cartStore = useCartStore();
  const stripe = useStripe();
  const elements = useElements();
  const [buttonColor, setButtonColor] = useState('#093636');
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (payButtonColor) {
      setButtonColor(payButtonColor);
    }
    const handlePaymentRequestSubmit = async (paymentEvent) => {
      if (cardPaymentStore.isTest) {
        cardPaymentStore.success = true;
        return;
      }
      setIsLoading(true);
      const result = await stripe.confirmCardPayment(
        cardPaymentStore.clientSecret,
        { payment_method: paymentEvent.paymentMethod.id },
        { handleActions: false }
      );
      if (result.error) {
        cardPaymentStore.setErrorMessage(result.error.message);
        paymentEvent.complete('fail');
      } else if (result.paymentIntent.status === 'requires_action') {
        // This call to confirmCardPayment lets Stripe handle extra steps for us,
        // such as 3D-secure and other validations.
        const actionResult = await stripe.confirmCardPayment(
          cardPaymentStore.clientSecret
        );
        if (actionResult.error) {
          setIsLoading(false);
          cardPaymentStore.setErrorMessage(actionResult.error.message);
        }
        paymentEvent.complete('success');
      } else {
        paymentEvent.complete('success');
        setIsLoading(false);
      }
    };

    if (stripe && !paymentRequest) {
      const newPaymentRequest = stripe.paymentRequest({
        country: 'SE',
        currency: 'sek', // Fixa när vi börjar stödja euro i stripe
        total: {
          label: `${cardPaymentStore.companyName} - Enklakassan`,
          amount: cardPaymentStore.total.amount,
        },
        requestPayerName: false,
        requestPayerPhone: false,
        requestPayerEmail: true,
      });

      newPaymentRequest.on('paymentmethod', (evt) => {
        handlePaymentRequestSubmit(evt);
      });

      newPaymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(newPaymentRequest);
        }
      });
    }
  }, [
    stripe,
    cardPaymentStore.total,
    cardPaymentStore.companyName,
    paymentRequest,
    cardPaymentStore.isTest,
    cardPaymentStore,
    payButtonColor,
  ]);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!stripe || !elements || cardPaymentStore.isLoading) {
      return;
    }

    if (
      !paymentStore.validateName() ||
      !paymentStore.validatePhone() ||
      !paymentStore.validateEmail()
    ) {
      setIsLoading(false);
      return;
    }

    if (payMethod) {
      cardPaymentStore.createOrUpdateDbCart(cartStore.items, payMethod);
    }

    if (cardPaymentStore.isTest) {
      cardPaymentStore.success = true;
      return;
    }
    cardPaymentStore.errorMessage = null;

    if (cardPaymentStore.paymentId) {
      cardPaymentStore.listenForPayment(cardPaymentStore.paymentId);
    }
    const result = await stripe.confirmCardPayment(
      cardPaymentStore.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      }
    );
    if (result.error) {
      setIsLoading(false);
      cardPaymentStore.setErrorMessage(result.error.message);
    }
  };

  const renderProducts = () => {
    const rows = cardPaymentStore.products?.map((cartItem) => {
      let discountText = null;
      if (cartItem.discount) {
        if (cartItem.discount.type === DiscountTypes.AMOUNT) {
          discountText = `- ${cartItem.discount.amount.format()}`;
        } else if (cartItem.discount.type === DiscountTypes.PERCENTAGE) {
          discountText = `- ${cartItem.discount.amount}%`;
        }
      }
      return (
        <tr key={cartItem.id}>
          <td>{cartItem.product.title}</td>
          <td>{cartItem.price.format()}</td>
          <td>{cartItem.count}</td>
          <td>{discountText}</td>
          <td>{calculateTotal(cartItem).format()}</td>
        </tr>
      );
    });

    return (
      <table className="table table-sm">
        <thead>
          <tr>
            <td>{t('cart.product')}</td>
            <td>{t('cart.price')}</td>
            <td>{t('cart.amount')}</td>
            <td>{t('cart.discount')}</td>
            <td>{t('cart.total')}</td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const renderTotal = () => {
    let discountText = null;
    if (cardPaymentStore.discount) {
      if (cardPaymentStore.discount.type === DiscountTypes.PERCENTAGE) {
        discountText = `${t('cart.discount')}: - ${
          cardPaymentStore.discount.amount
        }%`;
      }
    }
    return (
      <div>
        <p className="h3 font-weight-bold mb-0">
          {` ${discountText ? t('cart.priceAfterDiscount') : t('cart.price')} ${
            cardPaymentStore.total.format() || ''
          }`}
        </p>
        {cardPaymentStore.discount && (
          <div>
            <small>{discountText}</small>
          </div>
        )}
        <small>
          {t('cart.whereOfVat')}: {cardPaymentStore.tax.format()}
        </small>
      </div>
    );
  };

  const renderTestCard = () => {
    const now = new Date();

    return (
      <div className="d-flex align-items-center flex-column mt-3 test-card">
        <img src={logo} alt="Enklakassan" />
        <span className="pt-3 large">4242 4242 4242 4242</span>
        <div className="d-flex flex-row justify-content-between w-50 mt-2">
          <span className="small">
            {(now.getMonth() + 1).toString().padStart(2, '0')}/
            {(now.getFullYear() + 1).toString().substring(2)}
          </span>
          <span className="small">123</span>
        </div>
        <span className="mt-3 small name">Test Testsson</span>
      </div>
    );
  };

  const renderForm = () => (
    <div className={renderCart ? 'p-2' : ''}>
      {renderCart && <h3>{t('cart.cart')}</h3>}

      {cardPaymentStore.products ? (
        <div>
          {renderCart && renderProducts()}
          {renderCart && renderTotal()}
        </div>
      ) : (
        <div>{t('cart.noItems')}</div>
      )}

      <div
        className={renderCart ? 'row mb-1' : 'd-flex justify-content-center'}
      >
        {renderCart && (
          <div className="col-auto">
            <span className="h3">{t('card.payment')}</span>
          </div>
        )}
        <div className="col mb-3">
          {paymentRequest && (
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          )}
        </div>
      </div>
      <div>
        <div className="mb-3 card-input">
          <CardNumberElement
            options={{ ...CARD_ELEMENT_OPTIONS, showIcon: true }}
          />
        </div>

        <div className="row">
          <div className="col-6">
            <div className="card-input">
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>

          <div className="col-6">
            <div className="card-input">
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
        </div>

        {cardPaymentStore.errorMessage && (
          <div className="text-danger mt-3">
            {cardPaymentStore.errorMessage}
          </div>
        )}

        <div className="row">
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-lg mt-3"
              style={{
                backgroundColor: buttonColor || '#093636',
                color: isColorDark(buttonColor) ? '#000000' : '#ffffff',
              }}
              disabled={!stripe || isLoading}
              onClick={handleSubmit}
            >
              {t('card.pay')}
              {isLoading && !cardPaymentStore.errorMessage && (
                <span
                  className="spinner-border spinner-border-sm text-light ml-2"
                  style={{
                    position: 'relative',
                    top: -3,
                  }}
                />
              )}
            </button>
          </div>
        </div>
        {cardPaymentStore.isTest && (
          <>
            <div className="row mt-5">
              <span>
                {t('card.thisIsATestPayment')}
                <br />
                {t('card.useATestCard')}
              </span>
            </div>
            <div className="row mb-2">{renderTestCard()}</div>
          </>
        )}
      </div>
    </div>
  );

  const renderSuccess = () => (
    <>
      <div className="">{t('card.paymentComplete')}</div>
      {cardPaymentStore.isTest && <div>{t('card.thisWasATestPayment')}</div>}
    </>
  );

  const renderLoadError = () => (
    <div className="d-flex justify-content-center">
      {t('card.paymentCouldNotBeStarted')}
    </div>
  );

  const renderNonError = () =>
    cardPaymentStore.success ? renderSuccess() : renderForm();

  return (
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-xs-12 col-lg-12">
          <div>
            {renderCart && (
              <div className="card-header">
                <h2 className="my-0">
                  {cardPaymentStore.error
                    ? t('error.anErrorOccurred')
                    : cardPaymentStore.companyName}
                </h2>
              </div>
            )}
            <div>
              {cardPaymentStore.error ? renderLoadError() : renderNonError()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CardForm;
