import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../stores';
import functions from '../firebase/functions';

const AppInfoView = () => {
  const auth = useAuthStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const authCode = queryParams.get('code');
  const state = queryParams.get('state');
  const [companyId, setCompanyId] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const companiesSnap = await firebase
        .firestore()
        .collection('users')
        .doc(auth.user.uid)
        .collection('companies')
        .get();

      if (companiesSnap.size === 0) {
        throw new Error('No company.');
      }

      setCompanyId(companiesSnap.docs[0].id);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12">
          <div className="card border">
            <div className="card-header">
              <h2 className="mb-0">
                {t('integrat.welcome', { email: auth.user.email })}
              </h2>
            </div>
            <div className="card-body">
              <p>{t('integrat.connectFortnox')}</p>
              <p>{authCode}</p>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={() => {
                  functions()
                    .httpsCallable('integrateWithFortnoxOath2')({
                      userId: auth.user.uid,
                      companyId: state,
                      authorizationCode: authCode,
                    })
                    .then((result) => {
                      // eslint-disable-next-line no-console
                      console.log(result);
                    })
                    .catch((e) => {
                      // eslint-disable-next-line no-console
                      console.log('error', e);
                    });
                }}
              >
                {t('integrat.connect')}
              </a>
              <br />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={() => {
                  window.open(
                    `https://apps.fortnox.se/oauth-v1/auth?client_id=cMoaImiHiuBI&redirect_uri=https://fortnox.enklakassan.nu/integrateFortnox&scope=bookkeeping&state=${companyId}&access_type=offline&response_type=code`,
                    '_blank'
                  );
                }}
              >
                {t('integrat.connect')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppInfoView;
