import React from 'react';

const LoadingView = ({ text }) => (
  <>
    <div className="row loading">
      <div className="col">
        <div className="spinner-border text-primary" />
        <br />
        <p>{text}</p>
      </div>
    </div>
  </>
);

export default LoadingView;
