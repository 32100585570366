import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';
import { getIntegrationUrl } from '../api/webshop';
import LoadingView from './LoadingView';
import capitalize from '../utils/capitalize';
import BOOKKEEPERS from '../constants/types/Bookkeepers';

const IntegrateView = () => {
  const route = useRouteMatch();
  const { authCode, companyId, bookkeeper } = route.params;
  const url = window.location.href;
  const parsedUrl = new URL(url);
  const queryParams = new URLSearchParams(parsedUrl.search);
  const companyName = queryParams.get('companyName');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const integrate = async () => {
    setIsLoading(true);
    const { integrationUrl } = await getIntegrationUrl(
      companyId,
      bookkeeper,
      authCode
    ).catch((e) => {
      Sentry.captureException(e, {
        extra: {
          url,
        },
      });
      setErrorMessage(t('integrat.vismaIntegrationFailed'));
    });
    window.location.href = integrationUrl;
    setIsLoading(false);
  };
  if (isLoading) {
    return <LoadingView text={t('common.loading')} />;
  }

  return (
    <div className="d-flex justify-content-center w-100 h-100">
      <div className="d-flex justify-content-center align-items-center flex-column">
        {Object.values(BOOKKEEPERS).includes(bookkeeper) ? (
          <>
            <h1>
              {t('integrat.integrating', {
                bookkeeper: capitalize(bookkeeper),
                companyName,
              })}
            </h1>
            <img src={logo} className="rounded" alt="applogo" width="20%" />
            <button
              type="button"
              className="btn btn-block btn-primary mt-3 w-25"
              onClick={() => integrate()}
            >
              {errorMessage ? t('common.tryAgain') : t('common.continue')}
            </button>
            {errorMessage && <p>{errorMessage}</p>}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center flex-column w-50">
            <img src={logo} className="rounded" alt="applogo" width="20%" />
            <h3 className="text-center mt-5">
              {t('integrat.onlyVismaAndFortnox')}
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrateView;
