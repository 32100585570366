import Money from '../constants/types/Money';
import { IS_STAGING } from '../environment';
import fetcher from '../utils/fetcher';
import {
  parseCardPaymentRequest,
  parseCart,
  parseProducts,
} from './requestParser/requestParser';

const STAGING_URL =
  'https://europe-west1-enklakassan-staging.cloudfunctions.net/api';

let URL = 'https://europe-west1-enkla-kassan.cloudfunctions.net/api';

if (IS_STAGING) {
  URL = STAGING_URL;
}

// URL = 'http://192.168.1.118:5002/enklakassan-staging/europe-west1/api';

export const parsePaymentIntent = (data) => {
  return {
    ...data,
    paymentMethods: data.paymentMethods.map((paymentMethod) => ({
      ...paymentMethod,
      amount: new Money(paymentMethod.amount, paymentMethod.amount.currency),
    })),
    receiptData: {
      ...data.receiptData,
      total: new Money(data.receiptData.total, data.receiptData.total.currency),
      rounding: data.receiptData.rounding
        ? new Money(data.receiptData.rounding, data.receiptData.total.currency)
        : undefined,
      products: parseProducts(data.receiptData.products),
    },
    paymentData: {
      ...data.paymentData,
      pendingPaymentMethod: data.paymentData?.pendingPaymentMethod
        ? {
            ...data.paymentData.pendingPaymentMethod,
            amount: new Money(
              data.paymentData.pendingPaymentMethod.amount,
              data.paymentData.pendingPaymentMethod.amount.currency
            ),
          }
        : undefined,
    },
  };
};

export const getWebshop = async (webshopId) => {
  const res = await fetch(`${URL}/v1/webshop/${webshopId}`).then((res) =>
    res.json()
  );

  res.articles = parseProducts(res.articles);

  return res;
};

export const createOrUpdateCart = (webshopId, body) =>
  fetch(`${URL}/v1/webshop/${webshopId}/cart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());

export const verifyCart = (webshopId, body) =>
  fetch(`${URL}/v1/webshop/${webshopId}/cart/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());

export const createSwishPaymentRequest = (body) =>
  fetcher(`${URL}/v1/swish/commerce`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
export const sendReceipt = (companyId, paymentId, body) =>
  fetch(`${URL}/v1/company/${companyId}/payment/${paymentId}/send`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());

export const getCardPaymentRequest = (clientSecret) => {
  return fetcher(`${URL}/v1/stripe/${clientSecret}`, {}).then((res) =>
    parseCardPaymentRequest(res)
  );
};

export const getPaymentIntent = (companyId, paymentIntentId) => {
  return fetcher(
    `${URL}/v1/company/${companyId}/payments/${paymentIntentId}`
  ).then((res) => parsePaymentIntent(res.paymentIntent));
};

export const getCart = (cartId) => {
  return fetcher(`${URL}/v1/cart/${cartId}`, {}).then((res) => parseCart(res));
};

export const createStripePurchase = async (companyId, body) => {
  return fetcher(`${URL}/v1/stripe/createPurchase/${companyId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const getReceiptPdf = async (companyId, receiptId) => {
  return fetcher(`${URL}/v1/webshop/download/${companyId}/${receiptId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getIntegrationUrl = async (companyId, bookkeeper, authCode) => {
  return fetcher(
    `${URL}/v1/webshop/bookkeeping/integrationUrl/${bookkeeper}/${companyId}/${authCode}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const integrateVisma = async (companyId, authorizationCode, scopes) => {
  return fetcher(`${URL}/v1/webshop/bookkeeping/visma/integrate/${companyId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ authorizationCode, scopes }),
  });
};

export const integrateFortnox = async (
  companyId,
  authorizationCode,
  scopes
) => {
  return fetcher(
    `${URL}/v1/webshop/bookkeeping/fortnox/integrate/${companyId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ authorizationCode, scopes }),
    }
  );
};
