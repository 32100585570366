import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import CartTable from '../components/CartTable';
import CartSumTable from '../components/CartSumTable';
import { useCartStore, usePaymentStore, useShopStore } from '../stores';
import isColorDark from '../utils/isColorDark';
import { NO_PAYMENT_STARTED } from '../constants/payment';

const CartView = observer(() => {
  const shopStore = useShopStore();
  const cartStore = useCartStore();
  const paymentStore = usePaymentStore();
  const { t } = useTranslation();
  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';

  const renderContent = () => {
    if (cartStore.articleCount === 0) {
      return (
        <div className="col col-xs-12 col-md-8 col-lg-6 mx-auto m-5 d-flex align-items-center flex-column">
          <p>{t('cart.cartEmpty')}</p>
          <Link
            to={`/${shopStore.id}`}
            className="btn  btn-block"
            style={{
              backgroundColor: shopStore.headerTitleColor,
              border: 'none',
              color: colorBasedOnBrightnessOfBackGround,
            }}
          >
            {t('cart.backToShop')}
          </Link>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col col-xs-12 col-md-8 col-lg-6 mx-auto">
          <div className="row">
            <div className="col">
              <CartTable />
            </div>
          </div>
          <div className="row">
            <div className="col col-6 d-none d-md-block" />
            <div className="col col-xs-12 col-md-6">
              {cartStore.articleCount > 0 && <CartSumTable />}
            </div>
          </div>
          <div className="row">
            <div className="col pb-5">
              <Link
                onClick={() => {
                  paymentStore.clear();
                }}
                to={`/${shopStore.id}/payment/${NO_PAYMENT_STARTED}`}
                className="btn  btn-block"
                style={{
                  backgroundColor: shopStore.headerTitleColor,
                  border: 'none',
                  color: colorBasedOnBrightnessOfBackGround,
                }}
              >
                <i
                  className="fas fa-money-bill-wave pr-2"
                  style={{ color: colorBasedOnBrightnessOfBackGround }}
                />
                {t('cart.pay')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCompanyInfo = () => {
    return (
      <div className="row">
        <div className="col col-xs-12 col-md-8 col-lg-6 mx-auto mb-5">
          <div>{shopStore.company.name}</div>
          <div>
            {` ${shopStore.company.street}, ${shopStore.company.postalcode} ${shopStore.company.city} `}
          </div>
          <div>{` ${shopStore.company.email}`}</div>
          <div>{` ${shopStore.company.phone}`}</div>
          <div>
            {t('company.orgNr')}:{` ${shopStore.company.organizationnumber}`}
          </div>
          <div>
            {shopStore.webshopSettings?.returnPolicy && (
              <Link to={`/${shopStore.id}/returnPolicy`}>
                <p className="link">{t('payment.returnPolicy')}</p>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <PageContainer
      shopStore={shopStore}
      cartStore={cartStore}
      title={t('cart.cart')}
      hideCartLine
    >
      {renderContent()}
      {renderCompanyInfo()}
    </PageContainer>
  );
});

export default CartView;
