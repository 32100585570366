import { action, computed, makeObservable, observable } from 'mobx';
import firebase from 'firebase';
import i18next from 'i18next';
import {
  createOrUpdateCart,
  createStripePurchase,
  getCardPaymentRequest,
  getPaymentIntent,
} from '../api/webshop';
import CART_STATUS from '../constants/cart';
import { PAY_METHODS } from '../constants/payment';
import PriceTypes from '../constants/priceTypes';
import { calculateTotal, getVatFromWithVat } from '../utils/price';
import Money from '../constants/types/Money';

const TEST_CLIENT_SECRETS = ['onboardingTestPayment'];

class CardPaymentStore {
  companyName = null;

  products = null;

  total = new Money(0);

  error = null;

  isTest = false;

  success = false;

  cartKey = null;

  isLoading = false;

  isPaymentLoading = false;

  clientSecret = null;

  paymentId = null;

  errorMessage = null;

  stripeAccountId = null;

  discount = null;

  paymentStatus = 'PENDING';

  constructor(shopStore, cartStore, paymentStore) {
    this.shopStore = shopStore;
    this.cartStore = cartStore;
    this.paymentStore = paymentStore;

    makeObservable(this, {
      success: observable,
      companyName: observable,
      cartKey: observable,
      isLoading: observable,
      isPaymentLoading: observable,
      clientSecret: observable,
      products: observable,
      total: observable,
      error: observable,
      isTest: observable,
      paymentId: observable,
      errorMessage: observable,
      stripeAccountId: observable,
      discount: observable,
      tax: computed,
      fetch: action,
      setErrorMessage: action,
      paymentStatus: observable,
    });
  }

  setErrorMessage(message) {
    this.errorMessage = message;
  }

  get tax() {
    if (!this.products) {
      return new Money(0);
    }

    const totalVat = this.products.reduce((total, article) => {
      const priceInclTaxes = calculateTotal(article, this.discount);
      const fullVat = getVatFromWithVat(priceInclTaxes, article.product.tax);

      return total.add(fullVat);
    }, new Money(0));

    return totalVat;
  }

  clear() {
    this.companyName = null;
    this.products = null;
    this.total = new Money(0);
    this.error = null;
    this.isTest = false;
    this.success = false;
    this.cartKey = null;
  }

  async createStripeCardPayment(companyID, payMethod) {
    this.error = null;
    if (this.total < 300) {
      this.error = i18next.t('card.minPrice');
      return;
    }
    await createStripePurchase(companyID, {
      products: this.products.map((product) => ({
        ...product,
        product,
        sizeOfItem: 1,
      })),
      totalAmount: this.total,
      isWebshopPurchase: true,
      paymentMethodId: payMethod.id,
      stripeAccountId: payMethod.stripeAccountId,
      cartKey: this.cartKey,
      appVersionNumber: 'WEBSHOP',
      customerReference: null,
    })
      .then(async (res) => {
        this.paymentId = res.id;
        await this.fetch(res.clientSecret);

        return res;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Failed to load stripe payment intent:', error);
        this.error = i18next.t('card.paymentCouldNotBeStarted');
        this.isLoading = false;
      });
  }

  // Only used for card payments in in the store, not webshop.
  async fetch(clientSecret) {
    if (TEST_CLIENT_SECRETS.includes(clientSecret)) {
      this.companyName = 'Testföretaget AB';
      this.total = new Money(15000);
      this.products = [
        {
          product: { title: 'Testprodukt' },
          price: new Money(5000),
          count: 3,
          tax: 25,
        },
      ];

      return;
    }
    const res = await getCardPaymentRequest(clientSecret).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Failed to load stripe payment intent:', error);

      this.error = i18next.t('card.paymentCouldNotBeStarted');
      this.clientSecret = null;
      this.isLoading = false;
    });

    if (res?.status === 'succeeded') {
      this.error = i18next.t('card.paymentCouldNotBeStarted');
      this.clientSecret = null;
      this.isLoading = false;
    } else if (res) {
      this.clientSecret = res.clientSecret;
      this.companyName = res.company.name;
      this.products = res.products;
      this.total = res.totalAmount;
      this.isLoading = false;
      this.stripeAccountId = res.stripeAccountId;
      this.paymentId = res.paymentId;
      this.discount = res.discount;
    }
  }

  async fetchPaymentIntent(companyID, paymentIntentId) {
    const response = await getPaymentIntent(companyID, paymentIntentId);
    if (!response.paymentData.pendingPaymentMethod) {
      this.errorMessage =
        response.paymentData.error.message || i18next.t('card.paymentFailed');
    } else {
      this.clientSecret = response.paymentData.clientSecret;
      this.companyName = response.paymentData.companyName || '';
      this.products = response.receiptData.products;
      this.total = response.paymentData.pendingPaymentMethod.amount;
      this.isLoading = false;
      this.stripeAccountId = response.paymentData.stripeAccountId;
      this.paymentId = paymentIntentId;
      this.discount = response.receiptData.discount;
      this.paymentStatus = response.paymentData.paymentStatus;
      while (!this.success && !this.errorMessage) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const response = await getPaymentIntent(companyID, paymentIntentId);
        this.paymentStatus = response.paymentData.paymentStatus;
        if (response.receipt || !response.paymentData.pendingPaymentMethod) {
          this.success = true;
          break;
        } else if (this.paymentStatus === 'FAILED') {
          this.errorMessage = i18next.t('card.paymentFailed');
          break;
        }
      }
    }
  }

  async InitiateStripeCardPayment(payMethod) {
    this.isLoading = true;
    await this.createOrUpdateDbCart(this.cartStore.items, payMethod);
    const products = this.cartStore.items.map((item) => {
      const itemWithData = item.article;
      return {
        count: item.count,
        id: itemWithData.id,
        price: itemWithData.price,
        priceType: PriceTypes.STATIC,
        tax: itemWithData.tax,
        title: itemWithData.title,
        type: 'produkt',
        unitType: itemWithData.unitType,
      };
    });
    this.products = products;
    this.total = this.cartStore.sum;
    this.createStripeCardPayment(this.shopStore.companyId, payMethod);
  }

  async createOrUpdateDbCart(items, paymentMethod) {
    return createOrUpdateCart(this.shopStore.id, {
      created: new Date().toISOString(),
      status: CART_STATUS.CREATED,
      companyKey: this.shopStore.company.key,
      orderId: this.shopStore.nextWebshopOrderId,
      payMethod: PAY_METHODS.CARD,
      paymentId: this.paymentId || '',
      paymentMethod,
      customerEmail: this.paymentStore.email,
      customerPhone: this.paymentStore.phone,
      customerName: this.paymentStore.name,
      extraInformation: this.paymentStore.extraInformation,
      articles: items.map((item) => {
        const { article } = item;
        return {
          id: article.id,
          article,
          title: article.title,
          priceNoVat: article.priceNoVat,
          price: article.price,
          tax: article.tax,
          count: item.count,
          unitType: article.unitType,
          description: item.description,
          priceType: article.priceType,
        };
      }),
    }).then((res) => {
      this.cartKey = res.key;
    });
  }

  listenForPayment(paymentId) {
    const stopListening = firebase
      .firestore()
      .collection('cardPayments')
      .doc(paymentId)
      .onSnapshot((snap) => {
        const data = snap.data();
        if (!data) {
          // Observing a payment that does not exist yet
          return;
        }
        if (data.status === 'succeeded' && data.receiptStatus === 'created') {
          this.success = true;
          stopListening();
        } else if (
          data.receiptStatus === 'error' ||
          data.status === 'payment_failed'
        ) {
          this.errorMessage = i18next.t('card.paymentFailed');
          stopListening();
        }
      });
  }
}

export default CardPaymentStore;
