const ZeroPadString = (x) => {
  if (x < 10) {
    return `0${x}`;
  }
  return x;
};

const toYMDHMS = (swishFormattedDate) => {
  const dateObject = new Date(swishFormattedDate);

  const year = dateObject.getFullYear();
  let month = dateObject.getMonth() + 1;
  let day = dateObject.getDate();
  let h = dateObject.getHours();
  let min = dateObject.getMinutes();
  let s = dateObject.getSeconds();
  month = ZeroPadString(month);
  day = ZeroPadString(day);
  h = ZeroPadString(h);
  min = ZeroPadString(min);
  s = ZeroPadString(s);

  const formattedDate = `${year}/${month}/${day} - ${h}:${min}:${s}`;

  return formattedDate;
};

export default toYMDHMS;
