import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import PaymentFinishedView from '../views/PaymentFinishedView';
import PaymentView from '../views/PaymentView';
import ArticleView from '../views/ArticleView';
import CartView from '../views/CartView';
import CartUpdateView from '../views/CartUpdateView';
import MainShopView from '../views/MainShopView';
import NotFoundView from '../views/errors/NotFoundView';
import AppInfoView from '../views/AppInfoView';
import PasswordResetView from '../views/firebase_auth/PasswordResetView';
import CardPayment from '../views/CardPayment';
import LoadingRoute from './LoadingRoute';
import LoginView from '../views/Login';
import AuthenticatedRoute from './AuthenticatedRoute';
import { useShopStore } from '../stores';
import RegisterUserView from '../views/RegisterUserView';
import DownLoadFileView from '../views/DownLoadFileView';
import ReturnPolicyView from '../views/ReturnPolicyView';
import DownLoadReceiptView from '../views/DownloadReceiptView';
import IntegrateView from '../views/IntegrateView';
import NavigateToAppView from '../views/NavigateToAppView';
import PaymentIntent from '../views/PaymentIntent';

const MainRouter = observer(() => {
  const shopStore = useShopStore();

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginView />
        </Route>
        <Route path="/register">
          <RegisterUserView />
        </Route>
        <Route path="/stripe">
          <NavigateToAppView />
        </Route>
        <Route path="/download">
          <DownLoadFileView />
        </Route>

        <AuthenticatedRoute path="/appinfo">
          <AppInfoView />
        </AuthenticatedRoute>
        <Route path="/integrateFortnox">
          <NavigateToAppView />
        </Route>
        <Route path="/integrateVisma">
          <NavigateToAppView />
        </Route>
        <Route path="/integrate/:bookkeeper/:companyId/:authCode">
          <IntegrateView />
        </Route>
        <Route path="/auth/passwordreset">
          <PasswordResetView />
        </Route>
        <Route path="/card/:clientSecret">
          <CardPayment />
        </Route>
        <Route path="/company/:companyId/payment/:paymentIntentId">
          <PaymentIntent />
        </Route>
        <Route path="/receipt/:companyId/:receiptId">
          <DownLoadReceiptView />
        </Route>

        <LoadingRoute
          loaded={!shopStore.isLoading}
          path="/:shopId/paymentfinished"
          component={<PaymentFinishedView />}
        />
        <LoadingRoute
          loaded={!shopStore.isLoading}
          path="/:shopId/payment/:paymentId"
          component={<PaymentView />}
        />
        <LoadingRoute
          loaded={!shopStore.isLoading}
          path="/:shopId/cartupdate"
          component={<CartUpdateView />}
        />
        <LoadingRoute
          loaded={!shopStore.isLoading}
          path="/:shopId/cart"
          component={<CartView />}
        />
        <LoadingRoute
          loaded={!shopStore.isLoading}
          path="/:shopId/returnPolicy"
          component={<ReturnPolicyView />}
        />
        <LoadingRoute
          loaded={!shopStore.isLoading}
          path="/:shopId/:articleId"
          component={<ArticleView />}
        />
        <LoadingRoute
          loaded={!shopStore.isLoading}
          path="/:shopId"
          component={<MainShopView />}
        />

        <Route path="*">
          <NotFoundView />
        </Route>
      </Switch>
    </Router>
  );
});

export default MainRouter;
