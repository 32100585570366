import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../stores';
import logo from '../assets/logo.png';

const LoginView = () => {
  const location = useLocation();
  const history = useHistory();
  const auth = useAuthStore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const Login = () => {
    setLoading(true);
    setConfirmError('');

    auth
      .login(email, password)
      .then(() => {
        // navigate to redirect URl
        if (location.state?.from) {
          history.push(location.state?.from);
        } else {
          history.push('/appinfo');
        }
      })
      .catch((error) => {
        if (error.code === 'auth/weak-password') {
          setConfirmError(t('login.passwordTooWeak'));
        } else {
          setConfirmError(t('error.anErrorOccurred'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container">
      <div className="logoInLoginViewContainer">
        <img src={logo} alt="Logo" width="150px" className="logoInLoginView" />
      </div>
      <div className="row mt-5 justify-content-center">
        <div className="col-12 col-lg-6 col-md-8">
          <div className="card border">
            <div className="card-header">
              <h2 className="mb-0">{t('login.ekLogin')}</h2>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="input-newpassword">
                      {t('login.email')}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="input-newpassword"
                      onChange={(evt) => setEmail(evt.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="input-newpassword2">
                      {t('login.password')}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="input-newpassword2"
                      onChange={(evt) => setPassword(evt.target.value)}
                    />
                  </div>
                  <div className="loginButtonContainer">
                    <button
                      type="button"
                      className="btn btn-primary mt-2 loginUserButton"
                      style={{ minWidth: 120 }}
                      onClick={() => Login()}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm text-light" />
                      ) : (
                        t('login.login')
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary mt-2 "
                      style={{ minWidth: 120 }}
                      onClick={() => history.push('/register')}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm text-light" />
                      ) : (
                        t('login.createAccount')
                      )}
                    </button>
                  </div>
                  {confirmError && (
                    <div className="text-danger mt-3">{confirmError}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
