import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';

const RegisterUserView = () => {
  const { t } = useTranslation();

  return (
    <div className="registerUserMainContainer">
      <div>
        <img src={logo} alt="Logo" width="150px" className="logoInLoginView" />
      </div>
      <div className="d-flex justify-content-center flex-column mt-5">
        <p className="registerInfoText">{t('company.downloadApp')}</p>
      </div>
      <div className="mt-5 buttonContainerRegister">
        <a href="https://apps.apple.com/se/app/enklakassan/id1502092560">
          <img
            src="https://uploads-ssl.webflow.com/60313ff4e7a783b8bcc51425/607bff4f9205e0b4b4629088_appstoreblack.svg"
            alt="Logo"
            height="50px"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.axenu.enklakassan">
          <img
            src="https://uploads-ssl.webflow.com/60313ff4e7a783b8bcc51425/6090558f96dbbf476097f09c_google-play-badge-p-500.png"
            alt="Logo"
            height="50px"
            className="registerAndroidDownloadImg"
          />
        </a>
      </div>
    </div>
  );
};
export default RegisterUserView;
