// This should be the ideal format for these values.
const PaymentMethodConfigTypes = {
  SWISH_TRADE: 'SWISH_TRADE',
  SWISH_COMPANY: 'SWISH_COMPANY',
  SWISH_TEST: 'SWISH_TEST',
  SWISH: 'SWISH_COMPANY',
  CASH: 'CASH',
  TIDYPAY: 'TIDYPAY',
  PCI: 'PCI',
  STRIPE: 'STRIPE',
  EXTERNAL_CARD_MACHINE: 'EXTERNAL_CARD_MACHINE',
  OTHER: 'OTHER',
  BANK_TRANSFER: 'BANK_TRANSFER',
  SWISH_PAYOUT: 'SWISH_PAYOUT',
  TERMINAL: 'TERMINAL',
};

export default PaymentMethodConfigTypes;
