import { CountryShort } from './country';

export const Currency = {
  SEK: 'SEK',
  EUR: 'EUR',
};

export const CurrencyText = {
  SEK: 'Kronor (Svenska)',
  EUR: 'Euro',
};

export const CurrencySymbol = {
  SEK: 'kr',
  EUR: '€',
};

class Money {
  amount; // in cents

  currency; // default to SEK

  locale = CountryShort.SWEDEN; // default to Sweden

  constructor(amount, currency = Currency.SEK, locale = CountryShort.SWEDEN) {
    if (amount instanceof Money || typeof amount === 'object') {
      this.amount = amount.amount;
      this.currency = amount.currency;
      return;
    }
    this.amount = amount;
    this.currency = currency;
    this.locale = locale;
  }

  format() {
    const PriceFormatter = new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currency, // 'SEK',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const formattedPrice = PriceFormatter.format(this.asDecimal())
      .replace(/\u00A0/g, ' ')
      .replace('−', '-');
    return formattedPrice;
  }

  multiply(multiplier) {
    return new Money(this.amount * multiplier, this.currency, this.locale);
  }

  subtract(subtrahend) {
    if (typeof subtrahend === 'number') {
      return new Money(this.amount - subtrahend, this.currency, this.locale);
    }
    return new Money(
      this.amount - subtrahend.amount,
      this.currency,
      this.locale
    );
  }

  divide(divisor) {
    return new Money(this.amount / divisor, this.currency, this.locale);
  }

  add(addend) {
    if (typeof addend === 'number') {
      return new Money(this.amount + addend, this.currency, this.locale);
    }
    return new Money(this.amount + addend.amount, this.currency, this.locale);
  }

  round() {
    return new Money(Math.round(this.amount), this.currency, this.locale);
  }

  abs() {
    return new Money(Math.abs(this.amount), this.currency, this.locale);
  }

  asDecimal() {
    return Math.round(this.amount) / 100;
  }

  toJSON() {
    return {
      amount: this.amount,
      currency: this.currency,
    };
  }
}
export default Money;
