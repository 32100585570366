import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundView = () => {
  const { t } = useTranslation();
  return (
    <div className="message-wrapper">
      <img src="error.svg" alt="Webshop" />
      <p>{t('error.pageNotFound')}</p>
    </div>
  );
};

export default NotFoundView;
