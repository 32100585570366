import i18next from 'i18next';

export const UnitTypes = () => {
  return {
    DEFAULT: {
      unit: i18next.t('UnitTypes.DEFAULT.unit'),
      pre: i18next.t('UnitTypes.DEFAULT.pre'),
      id: 'DEFAULT',
    },
    APIECE: {
      unit: i18next.t('UnitTypes.APIECE.unit'),
      pre: i18next.t('UnitTypes.APIECE.pre'),
      id: 'APIECE',
    },
    KILOGRAM: {
      unit: i18next.t('UnitTypes.KG.unit'),
      pre: i18next.t('UnitTypes.KG.pre'),
      id: 'KILOGRAM',
    },
    KG: {
      unit: i18next.t('UnitTypes.KG.unit'),
      pre: i18next.t('UnitTypes.KG.pre'),
      id: 'KG',
    },
    LITER: {
      unit: i18next.t('UnitTypes.LITRE.unit'),
      pre: i18next.t('UnitTypes.LITRE.pre'),
      id: 'LITER',
    },
    METER: {
      unit: i18next.t('UnitTypes.METER.unit'),
      pre: i18next.t('UnitTypes.METER.pre'),
      id: 'METER',
    },
    HOUR: {
      unit: i18next.t('UnitTypes.HOUR.unit'),
      pre: i18next.t('UnitTypes.HOUR.pre'),
      id: 'HOUR',
    },
  };
};
export const getUnitType = (unit, customUnits) => {
  const unitType = Object.values(UnitTypes()).find((type) => type.id === unit);

  if (unitType) {
    return unitType;
  }
  const customUnit = customUnits.find((customUnit) => customUnit.id === unit);

  if (customUnit) {
    return customUnit;
  }
  return UnitTypes().DEFAULT;
};

export const getTag = (tagId, tags) => {
  const customUnit = tags.find((tag) => tag.id === tagId);
  if (customUnit) {
    return customUnit;
  }
  return null;
};
