import { makeObservable, observable, action } from 'mobx';
import { getCart, sendReceipt } from '../api/webshop';
import toYMDHMS from '../utils/date';

export default class ReceiptStore {
  shopStore = null;

  companyKey = '';

  cartKey = '';

  paymentId = '';

  articles = [];

  paymentDate = '';

  customerEmail = '';

  isLoading = false;

  orderId = '';

  constructor() {
    makeObservable(this, {
      companyKey: observable,
      cartKey: observable,
      paymentId: observable,
      articles: observable,
      paymentDate: observable,
      customerEmail: observable,
      isLoading: observable,
      orderId: observable,

      loadCart: action,
      setIsLoading: action,
      sendReceipt: action,
    });
  }

  clear() {
    this.companyKey = '';
    this.cartKey = '';
    this.paymentId = '';
    this.articles = [];
    this.paymentDate = '';
    this.customerEmail = '';
    this.isLoading = false;
  }

  async loadCart(cartKey, paymentId) {
    this.clear();
    this.setIsLoading(true);

    this.cartKey = cartKey;

    const cart = await getCart(cartKey);
    this.orderId = cart.orderId;
    this.paymentId = cart.paymentId || paymentId;
    this.companyKey = cart.companyKey;
    this.articles = cart.articles;
    this.customerEmail = cart.customerEmail;
    this.setIsLoading(false);
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  sendReceipt(email) {
    return sendReceipt(this.companyKey, this.paymentId, {
      formattedDate: toYMDHMS(new Date()),
      email,
    });
  }
}
