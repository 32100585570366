import React, { useState } from 'react';
import i18next from 'i18next';
import { useShopStore } from '../stores';

const ShopHeader = () => {
  const shopStore = useShopStore();
  const [language, setLanguage] = useState(i18next.language);

  const handleChange = (event) => {
    setLanguage(event.target.value);
    i18next.changeLanguage(event.target.value);
  };

  const renderImage = () => {
    if (!shopStore.image) {
      return null;
    }

    return (
      <div className="shop-image-container">
        <a href={`/${shopStore.id}`}>
          <div className="shop-logo-container">
            <img
              src={shopStore.image}
              className="shop-logo"
              alt="Shop Logotype"
            />
          </div>
        </a>
      </div>
    );
  };

  return (
    <div
      className="row shop-header"
      style={{
        backgroundColor: shopStore.headerBackgroundColor,
        color: shopStore.headerTitleColor,
      }}
    >
      {renderImage()}

      <div className="shop-title-container">
        <a
          href={`/${shopStore.id}`}
          style={{ color: shopStore.headerTitleColor }}
        >
          <h1 className="shop-title" style={{ wordBreak: 'break-all' }}>
            {shopStore.title}
          </h1>
        </a>
      </div>
      <div style={{ position: 'absolute', top: 20, right: 20 }}>
        <select value={language} onChange={handleChange}>
          <option value="sv">Swedish</option>
          <option value="en">English</option>
        </select>
      </div>
    </div>
  );
};

export default ShopHeader;
