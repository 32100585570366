import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  useCardPaymentStore,
  useCartStore,
  useReceiptStore,
  useShopStore,
} from '../stores';
import LoadingView from '../views/LoadingView';
import CardForm from './CardForm';
import { STRIPE_API_PUBLISHABLE_KEY } from '../constants/apiKeys';
import isColorDark from '../utils/isColorDark';

const WebshopCardPayment = observer(({ payMethod }) => {
  const cardPaymentStore = useCardPaymentStore();
  const cartStore = useCartStore();
  const shopStore = useShopStore();
  const receiptStore = useReceiptStore();
  const [stripePromise, setStripePromise] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      cartStore.sum.amount >= 300 &&
      !shopStore.isLoading &&
      !cartStore.cartIsLoading
    ) {
      cardPaymentStore.InitiateStripeCardPayment(payMethod);
    }
  }, [
    cardPaymentStore,
    cartStore.cartIsLoading,
    payMethod.stripeAccountId,
    shopStore.isLoading,
    payMethod,
    cartStore.sum.amount,
  ]);

  useEffect(() => {
    if (
      (cartStore.sum.amount >= 300 && payMethod.stripeAccountId) ||
      cardPaymentStore.isTest
    ) {
      setStripePromise(
        loadStripe(STRIPE_API_PUBLISHABLE_KEY, {
          locale: 'sv',
          stripeAccount: cardPaymentStore.isTest
            ? undefined
            : payMethod.stripeAccountId,
        })
      );
    }
  }, [
    cardPaymentStore.isTest,
    payMethod.stripeAccountId,
    cartStore.sum.amount,
  ]);

  useEffect(() => {
    if (cardPaymentStore.success && cardPaymentStore.cartKey) {
      localStorage.setItem('cartKey', cardPaymentStore.cartKey);
      localStorage.setItem('paymentId', cardPaymentStore.paymentId);
      history.replace(
        `/${shopStore.id}/paymentfinished/${cardPaymentStore.cartKey}`
      );
    }
  }, [
    cardPaymentStore,
    cardPaymentStore.success,
    cartStore,
    history,
    receiptStore,
    shopStore,
    shopStore.id,
  ]);

  return (
    <div>
      {cardPaymentStore.clientSecret &&
        stripePromise &&
        !cardPaymentStore.isLoading && (
          <Elements stripe={stripePromise}>
            <CardForm
              payButtonColor={shopStore.headerTitleColor}
              payMethod={payMethod}
            />
          </Elements>
        )}
      {cardPaymentStore.isLoading && <LoadingView />}
      {cartStore.sum.amount < 300 && (
        <div className="text-center">{t('card.minPrice')}</div>
      )}
      {cardPaymentStore.error && !cardPaymentStore.isLoading && (
        <div className="d-flex justify-content-center align-items-center flex-column text-center p-">
          <button
            style={{
              backgroundColor: shopStore.headerBackgroundColor || '#093636',
              color: isColorDark(shopStore.headerBackgroundColor)
                ? '#000000'
                : '#ffffff',
              border: 'none',
              boxShadow: 'none',
            }}
            className="btn btn-block btn-primary mt-3 w-50"
            type="button"
            onClick={() => {
              cardPaymentStore.InitiateStripeCardPayment(payMethod);
            }}
          >
            {t('common.tryAgain')}
          </button>
        </div>
      )}
    </div>
  );
});
export default WebshopCardPayment;
