import Money from '../../constants/types/Money';

export const parseProduct = (product) => {
  return {
    ...product,
    price: new Money(product.price),
    priceNoVat: new Money(product.priceNoVat),
  };
};

export const parseProducts = (products) => {
  return products.map((product) => {
    return parseProduct(product);
  });
};

export const parseCart = (cart) => {
  return {
    ...cart,
    articles: parseProducts(cart.articles),
  };
};

const parseCardPaymentProducts = (products) => {
  return products.map((product) => {
    return {
      ...product,
      price: new Money(product.price),
      discount:
        product?.discount?.type === 'AMOUNT'
          ? {
              ...product.discount,
              amount: new Money(product.discount.amount),
            }
          : product.discount,
      product: {
        ...product.product,
        price: new Money(product.product.price),
      },
    };
  });
};

export const parseCardPaymentRequest = (cardPaymentRequest) => {
  return {
    ...cardPaymentRequest,
    products: parseCardPaymentProducts(cardPaymentRequest.products),
    total: new Money(cardPaymentRequest.total),
    totalAmount: new Money(cardPaymentRequest.totalAmount),
  };
};
