import { action, makeObservable, observable } from 'mobx';
import firebase from 'firebase';

export default class AuthStore {
  isAuthenticated = false;

  user = null;

  isLoading = true;

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      isLoading: observable,
      user: observable,
      login: action,
    });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.isAuthenticated = true;
        this.user = user;
      }
      this.isLoading = false;
    });
  }

  login(email, password) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (result.user) {
          this.isAuthenticated = true;
          this.user = result.user;
        }
        return result.user;
      });
  }
}
