import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCartStore, useShopStore } from '../stores';
import isColorDark from '../utils/isColorDark';
import { getUnitType, UnitTypes } from '../utils/product';
import CountInputModal from './CountInputModal';
import ProductTags from './ProductTags';

const ShopArticleInfo = observer(({ article, onAddToCart, customUnits }) => {
  const shopStore = useShopStore();
  const cartStore = useCartStore();
  const [isimageLoaded, setIsImageLoaded] = useState(false);
  const [showModalInputUnits, setShowModalInputUnits] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const { t } = useTranslation();

  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';

  const renderImage = () => (
    <>
      <img
        className="shop-info-image"
        style={{ display: isimageLoaded ? 'flex' : 'none' }}
        src={article.image}
        alt={article.title}
        onLoad={() => {
          setIsImageLoaded(true);
        }}
      />

      {!isimageLoaded && (
        <div
          className="shop-info-image-placeholder"
          style={{ backgroundColor: shopStore.headerBackgroundColor }}
        >
          <i
            className="far fa-image"
            style={{ color: shopStore.headerTitleColor }}
          />
        </div>
      )}
    </>
  );
  const addToCart = () => {
    if (outOfStock) {
      return;
    }
    if (
      getUnitType(article.unitType, customUnits).unit ===
      UnitTypes().DEFAULT.unit
    ) {
      onAddToCart(article.id, 1, additionalInfo);
    } else {
      setShowModalInputUnits(true);
    }
  };

  const outOfStock = article.stockRemaining === 0;

  const description = article.description ? (
    <span className="preserve-line-breaks" style={{ whiteSpace: 'pre-line' }}>
      {article.description}
    </span>
  ) : (
    <span className="text-muted">
      <em>{t('product.noDescription')}</em>
    </span>
  );

  return (
    <>
      <div className="shop-info-container row">
        <div className="col col-xs-12 col-md-8 col-lg-6 mx-auto">
          <div className="row">
            <div className="col">{renderImage()}</div>
          </div>
          <div className="row">
            <div className="col pt-3">
              {description}
              <ProductTags articleTags={article.tags} />
            </div>
          </div>
          <div className="row">
            <div className="col pt-3 shop-info-price">{`${article.price.format()}/ ${
              getUnitType(article.unitType, customUnits).pre
            }`}</div>
          </div>
          <div className="row">
            <div className="col pt-3">
              <input
                type="text"
                className="form-control"
                placeholder={t('product.informationToSeller')}
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                maxLength={100}
              />
            </div>
          </div>
          <div className="row">
            <div className="col pt-3">
              <button
                type="button"
                className="btn btn-block"
                style={{
                  backgroundColor: shopStore.headerTitleColor,
                  border: 'none',
                  color: colorBasedOnBrightnessOfBackGround,
                }}
                disabled={outOfStock}
                onClick={() => {
                  if (onAddToCart) {
                    addToCart();
                  }
                }}
              >
                {outOfStock ? (
                  t('product.soldOut')
                ) : (
                  <>
                    <i
                      className="fas fa-cart-plus pr-1"
                      style={{ color: colorBasedOnBrightnessOfBackGround }}
                    />
                    {t('product.addToCart')}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModalInputUnits && (
        <CountInputModal
          article={article}
          onSubmit={(count) => {
            onAddToCart(article.id, count, additionalInfo);
            setShowModalInputUnits(false);
          }}
          onCancel={() => setShowModalInputUnits(false)}
          customUnits={shopStore.customUnits}
          articleCountInCart={
            cartStore.getItemByArticleId(article.id)
              ? cartStore.getItemByArticleId(article.id).count
              : 0
          }
        />
      )}
    </>
  );
});

export default ShopArticleInfo;
