import React from 'react';
import radioButtonOff from '../assets/radio-button-off-outline.svg';
import radioButtonOn from '../assets/radio-button-on-outline.svg';

const Accordion = ({
  title,
  children,
  index,
  activeIndex,
  setActiveIndex,
  imageSource,
  redioButton,
}) => {
  const handleSetIndex = (index) =>
    activeIndex !== index && setActiveIndex(index);
  return (
    <div className="accordionContainer">
      <div
        onClick={() => handleSetIndex(index)}
        className="flex w-1/2 justify-between p-2 mt-2 rounded bg-red "
      >
        <div className="accorion">
          {redioButton &&
            (activeIndex === index ? (
              <img src={radioButtonOn} width="20px" alt="off" />
            ) : (
              <img src={radioButtonOff} width="20px" alt="off" />
            ))}
          <div className="accordionTitle">{title}</div>
          {imageSource && <img src={imageSource} width="100px" alt="off" />}
        </div>

        <div
          className="accordionItemExpanded"
          style={{ maxHeight: activeIndex === index ? '100vh' : 0 }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
