import {
  makeObservable,
  action,
  observable,
  runInAction,
  computed,
} from 'mobx';
import i18next from 'i18next';
import { getUnitType } from '../utils/product';
import PriceTypes from '../constants/priceTypes';
import { getWebshop } from '../api/webshop';
import { PAYMENTMETHOD_CONFIG_TYPES } from '../constants/payment';
import WEBSHOP_MODES from '../constants/shopModes';
import PaymentMethodConfigTypes from '../constants/paymentMethodConfigTypes';

export default class ShopStore {
  id = '';

  companyId = '';

  image = '';

  title = '';

  articles = [];

  headerBackgroundColor = '';

  headerTitleColor = '';

  description = '';

  swishPayeeAlias = '';

  swishMessage = '';

  swishCurrency = '';

  active = true;

  selfService = false;

  company = null;

  isLoading = false;

  error = false;

  swishTest = false;

  customUnits = [];

  tags = [];

  isCardActive = false;

  nextWebshopOrderId = null;

  webshopSettings = null;

  requirePhone = false;

  requireEmail = false;

  requireName = false;

  constructor() {
    makeObservable(this, {
      id: observable,
      companyId: observable,
      image: observable,
      title: observable,
      articles: observable,
      headerBackgroundColor: observable,
      headerTitleColor: observable,
      description: observable,
      nextWebshopOrderId: observable,
      swishPayeeAlias: observable,
      swishMessage: observable,
      swishCurrency: observable,
      isCardActive: observable,
      active: observable,
      selfService: observable,

      swishTest: observable,

      company: observable,

      isLoading: observable,
      error: observable,
      customUnits: observable,
      tags: observable,
      webshopSettings: observable,
      load: action,
      getWebshop: action,
      hasPacklist: computed,
      activePayMethods: computed,
    });
  }

  load(shopId) {
    this.isLoading = true;
    this.id = shopId;

    return this.getWebshop()
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Failed to load webshopContext:${error?.message}`);
        this.error = true;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  async getWebshop() {
    if (!this.id) {
      return Promise.reject(new Error('Missing shopId'));
    }
    this.error = false;
    const webshopContext = await getWebshop(this.id);
    this.companyId = webshopContext.company.key;
    this.image = webshopContext.company.logo;
    this.title = webshopContext.shop.title;
    document.title = this.title;
    this.headerBackgroundColor =
      webshopContext.shop.headerBackgroundColor ||
      webshopContext.shop.headerBackgroundcolor ||
      '#DFFFEA';
    this.headerTitleColor = webshopContext.shop.headerTitleColor ?? '#093636';
    this.description = webshopContext.shop.description;
    this.swishPayeeAlias =
      webshopContext.company.paymentMethods?.swishTrade?.number ||
      webshopContext.company.paymentMethods?.swish?.number ||
      webshopContext.company.swishNr ||
      webshopContext.company.phone;
    this.swishMessage =
      webshopContext.company.swishMessage ||
      i18next.t('swish.thanksForYourPurchase');
    this.swishCurrency = webshopContext.company.swishCurrency || 'SEK';
    this.selfService = webshopContext.shop.selfService;
    this.active = webshopContext.shop.active;
    this.isSwishTestActive =
      webshopContext.company.paymentMethods?.swishTest?.active;
    this.isSwishActive =
      webshopContext.company.paymentMethods?.swishTrade?.active ||
      webshopContext.company.paymentMethods?.swish?.active;
    this.isCardActive = webshopContext.company.paymentMethods?.card?.active;
    this.stripeAccountId =
      webshopContext.company.paymentMethods?.card?.stripeAccountId;
    this.paymentMethods = webshopContext.company.paymentMethods;
    this.articles = webshopContext.articles
      .filter(
        (article) =>
          !article.removed && article.priceType !== PriceTypes.DYNAMIC
      )
      .map((article) => ({
        id: article.key,
        image: article.image,
        title: article.title,
        description: article.description,
        price: article.price,
        priceNoVat: article.price.divide(1 + article.tax / 100),
        minimumAmountAllowed:
          getUnitType(article.unitType, this.customUnits)
            .minimumAmountAllowed ?? '1',
        tax: article.tax,
        unitType: article.unitType,
        tags: article.tags || [],
        stockRemaining: article.count,
        priceType: article.priceType,
      }))
      .sort((a, b) => (a.id < b.id ? -1 : 1));
    this.company = webshopContext.company;
    this.webshopSettings = webshopContext.webshopSettings;
    this.customUnits = webshopContext.customUnits;
    this.tags = webshopContext.tags;
    this.nextWebshopOrderId = webshopContext.company.nextWebshopOrderId;
    this.shopMode =
      webshopContext.shop.shopMode || WEBSHOP_MODES.SELFSERVICE_WITH_PACKLIST;
    this.requirePhone = webshopContext.shop.requirePhone;
    this.requireEmail = webshopContext.shop.requireEmail;
    this.requireName = webshopContext.shop.requireName;
    return Promise.resolve();
  }

  get activePayMethods() {
    const convertFromOldPaymentMethodFormat = (paymentMethodType) => {
      const PAYMENT_METHOD_HANDLE_OLD_FORMAT = {
        swish: PaymentMethodConfigTypes.SWISH,
        card: PaymentMethodConfigTypes.STRIPE,
        cash: PaymentMethodConfigTypes.CASH,
        softPos: PaymentMethodConfigTypes.TIDYPAY,
        externalCardMachine: PaymentMethodConfigTypes.EXTERNAL_CARD_MACHINE,
        swishCompany: PaymentMethodConfigTypes.SWISH_COMPANY,
        swishTrade: PaymentMethodConfigTypes.SWISH_TRADE,
        swishTest: PaymentMethodConfigTypes.SWISH_TEST,
        Swishhandel: PaymentMethodConfigTypes.SWISH_TRADE,
      };

      if (paymentMethodType in PAYMENT_METHOD_HANDLE_OLD_FORMAT) {
        return PAYMENT_METHOD_HANDLE_OLD_FORMAT[paymentMethodType];
      }
      return paymentMethodType;
    };

    return Object.entries(this.paymentMethods).reduce(
      (accumulator, [key, paymentMethod]) => {
        const type = convertFromOldPaymentMethodFormat(
          paymentMethod.type || key
        );
        if (
          (paymentMethod.type === PAYMENTMETHOD_CONFIG_TYPES.SWISH_TRADE ||
            paymentMethod.type === PAYMENTMETHOD_CONFIG_TYPES.STRIPE) &&
          paymentMethod.active === true
        ) {
          accumulator[key] = {
            ...paymentMethod,
            name:
              paymentMethod.name ||
              i18next.t(`DefaultNamesForPaymentMethodConfigTypes.${type}`),
            shortName:
              paymentMethod.shortName ||
              i18next.t(`DefaultShortNamesForPaymentMethodConfigTypes.${type}`),
            type,
            active: paymentMethod.active,
            id: key,
            isPayout: false,
          };
        }
        return accumulator;
      },
      {}
    );
  }

  getArticleById(articleId) {
    return this.articles.find(
      (article) => article.id.toString() === articleId.toString()
    );
  }

  get hasPacklist() {
    if (this.shopMode === WEBSHOP_MODES.SELFSERVICE_WITHOUT_PACKLIST) {
      return false;
    }
    if (
      this.shopMode === WEBSHOP_MODES.SELFSERVICE_WITH_PACKLIST ||
      this.shopMode === WEBSHOP_MODES.WEBSHOP
    ) {
      return true;
    }
    if (this.selfService) {
      return false;
    }
    return true;
  }
}
