import { observer } from 'mobx-react';
import React from 'react';
import { useShopStore } from '../stores';
import { getTag } from '../utils/product';

const ProductTagDots = observer(({ articleTags }) => {
  const shopStore = useShopStore();
  const { tags } = shopStore;
  return (
    <div className="productTagDotContainer">
      {articleTags.map((tag) => (
        <div
          className="productTagDot"
          style={{ backgroundColor: getTag(tag, tags)?.color }}
        />
      ))}
    </div>
  );
});
export default ProductTagDots;
