const PriceTypes = {
  DEFAULT: 'fast',
  STATIC: 'fast',
  DYNAMIC: 'rörligt',
  // Checks default for backcompatability
  is: (product, priceType) =>
    (product.priceType || PriceTypes.DEFAULT) === priceType,
};

export default PriceTypes;
