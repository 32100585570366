import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NO_PAYMENT_STARTED } from '../constants/payment';

import { PAYMENT_STATUS } from '../constants/swish';
import {
  useCartStore,
  usePaymentStore,
  useReceiptStore,
  useShopStore,
} from '../stores';
import isColorDark from '../utils/isColorDark';
import LoadingView from '../views/LoadingView';

const WebshopSwishPayment = observer(({ payMethod }) => {
  const paymentStore = usePaymentStore();
  const shopStore = useShopStore();
  const history = useHistory();
  const receiptStore = useReceiptStore();
  const cartStore = useCartStore();
  const { paymentId } = useParams();
  const [showLoadingPayment, setShowLoadingPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);
  const [showOpenSwishButton, setShowOpenSwishButton] = useState(false);
  const navigatedFromSwishApp = paymentId !== NO_PAYMENT_STARTED;
  const { t } = useTranslation();

  useEffect(() => {
    if (isMobile && navigatedFromSwishApp) {
      setShowLoadingPayment(true);
      paymentStore.listenForPayment(paymentId);
    }
    switch (paymentStore.paymentStatus) {
      case PAYMENT_STATUS.PAID: {
        receiptStore.companyKey = shopStore.company.key;
        localStorage.setItem('cartKey', paymentStore.dbCartKey);
        localStorage.setItem('paymentId', paymentStore.paymentId);
        receiptStore
          .loadCart(paymentStore.dbCartKey, paymentStore.paymentId)
          .then(() => {
            cartStore.clear();
          });
        history.replace(
          `/${shopStore.id}/paymentfinished/${paymentStore.dbCartKey}`
        );
        setShowLoadingPayment(false);
        break;
      }
      case PAYMENT_STATUS.PENDING:
        if (!isMobile) {
          setIsWaitingForPayment(false);
        } else {
          setTimeout(() => setShowOpenSwishButton(true), 2000);
        }
        break;
      case PAYMENT_STATUS.DECLINED:
        if (navigatedFromSwishApp && cartStore.items.length === 0) {
          setShowLoadingPayment(false);
          cartStore.getDbCart(paymentStore.dbCartKey);
          setShowLoadingPayment(false);
        }
        break;
      default:
        setShowOpenSwishButton(false);
        setIsWaitingForPayment(false);
    }
  }, [
    receiptStore,
    paymentStore.paymentStatus,
    paymentStore.orderId,
    paymentStore.dbCartKey,
    history,
    shopStore,
    paymentStore,
    paymentId,
    navigatedFromSwishApp,
    cartStore,
  ]);

  const processPayment = async () => {
    paymentStore.setSwishError('');
    setIsLoading(true);

    if (
      paymentStore.validatePhone() &&
      paymentStore.validateName() &&
      paymentStore.validateEmail()
    ) {
      setIsWaitingForPayment(true);
      await paymentStore.startPayment(payMethod).catch(async () => {
        setIsLoading(false);
      });
    }

    setIsLoading(false);
  };
  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';
  let paymentButtonSubtitle = t('swish.openSwish');
  if (isMobile) {
    paymentButtonSubtitle = t('swish.swishWillOpen');
  }
  if (shopStore.isSwishTestActive) {
    paymentButtonSubtitle = '';
  }
  if (showLoadingPayment) {
    return <LoadingView text={t('swish.paymentProcessed')} />;
  }
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label> {t('swish.mobileNumber')}: </label>
            <input
              type="tel"
              autoComplete="tel"
              id="tel"
              className={`form-control ${
                paymentStore.phoneError ? 'is-invalid' : ''
              }`}
              placeholder={!isMobile && '+46...'}
              value={paymentStore.phone}
              onChange={(evt) => {
                paymentStore.setPhone(evt.target.value);
              }}
            />
            {!paymentStore.phoneError && (
              <small className="form-text text-muted">
                {t('swish.swishNumberNeeded')}
              </small>
            )}
            {paymentStore.phoneError && (
              <small className="form-text text-danger">
                {paymentStore.phoneError}
              </small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p className="text-danger font-weight-bold">
            {paymentStore.swishError}
          </p>

          {(paymentStore.paymentStatus === PAYMENT_STATUS.DECLINED ||
            paymentStore.paymentStatus === PAYMENT_STATUS.ERROR) && (
            <small className="form-text text-danger">
              {t('swish.paymentAborted')}
            </small>
          )}
          {shopStore.isSwishTestActive && (
            <small className="form-text text-muted">
              {t('swish.swishTestActive')}
            </small>
          )}

          {((paymentStore.paymentStatus &&
            paymentStore.paymentStatus !== PAYMENT_STATUS.PAID &&
            paymentStore.paymentStatus !== PAYMENT_STATUS.DECLINED &&
            paymentStore.paymentStatus !== PAYMENT_STATUS.ERROR) ||
            isLoading) && (
            <>
              <p>{t('common.loading')}</p>
            </>
          )}

          {paymentStore.paymentStatus !== PAYMENT_STATUS.PENDING &&
            paymentStore.paymentStatus !== PAYMENT_STATUS.PAID &&
            !showOpenSwishButton && (
              <button
                type="button"
                className="btn btn-block"
                onClick={processPayment}
                disabled={isLoading || isWaitingForPayment}
                style={{
                  backgroundColor: shopStore.headerTitleColor,
                  border: 'none',
                  color: colorBasedOnBrightnessOfBackGround,
                }}
              >
                {isLoading || isWaitingForPayment ? (
                  <div className="spinner-border spinner-border-sm text-light" />
                ) : (
                  t('swish.payWithSwish')
                )}
              </button>
            )}
          {isMobile && showOpenSwishButton && !shopStore.isSwishTestActive && (
            <button
              type="button"
              className="btn btn-block"
              onClick={() => {
                window.location.replace(paymentStore.swishUrl);
              }}
              style={{
                backgroundColor: shopStore.headerTitleColor,
                border: 'none',
                color: colorBasedOnBrightnessOfBackGround,
              }}
            >
              {t('swish.goToSwish')}
            </button>
          )}

          {paymentStore.paymentStatus === PAYMENT_STATUS.PENDING && (
            <small className="form-text text-muted">
              {paymentButtonSubtitle}
            </small>
          )}
        </div>
      </div>
    </div>
  );
});
export default WebshopSwishPayment;
