import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import { useCartStore, useShopStore } from '../stores';
import isColorDark from '../utils/isColorDark';

const CartUpdateView = observer(() => {
  const shopStore = useShopStore();
  const cartStore = useCartStore();
  const { t } = useTranslation();
  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';

  return (
    <PageContainer title={t('cart.cart')} hideCartLine>
      <div className="container">
        <div className="row">
          <div className="col mt-3">
            <p>
              {t('cart.aProductIsSoldOut')}
              <br />
              {Object.keys(cartStore.failedArticles).map((key) => {
                const article = shopStore.getArticleById(key);
                if (article) {
                  return <div key={key}>{article.title}</div>;
                }
                return null;
              })}
              {t('cart.cartUpdateNeeded')}
            </p>
            <Link
              to={`/${shopStore.id}/cart`}
              className="btn mt-3"
              style={{
                backgroundColor: shopStore.headerTitleColor,
                border: 'none',
                color: colorBasedOnBrightnessOfBackGround,
              }}
            >
              <i
                className="fas fa-shopping-cart pr-2"
                style={{ color: colorBasedOnBrightnessOfBackGround }}
              />{' '}
              {t('cart.toCheckout')}
            </Link>
          </div>
        </div>
      </div>
    </PageContainer>
  );
});

export default CartUpdateView;
