import { IS_STAGING } from '../environment';

const SWISH_PAYMENT_CALLBACK_URL_STAGING =
  'https://europe-west1-enklakassan-staging.cloudfunctions.net/api/v1/swish/commerceCallback';
const SWISH_PAYMENT_CALLBACK_URL_PROD =
  'https://europe-west1-enkla-kassan.cloudfunctions.net/api/v1/swish/commerceCallback';
const SWISH_PAYMENT_CALLBACK_URL = IS_STAGING
  ? SWISH_PAYMENT_CALLBACK_URL_STAGING
  : SWISH_PAYMENT_CALLBACK_URL_PROD;

const PAYMENT_STATUS = {
  PAID: 'PAID',
  DECLINED: 'DECLINED',
  ERROR: 'ERROR',
  PENDING: 'PENDING',
};

const SWISH_ERRORS = {
  AM21: 'AM21',
  PA01: 'PA01',
  RP06: 'RP06',
  ACMT07: 'ACMT07',
  // Self-made errors
  NOCON: 'NOCON',
};

export { SWISH_PAYMENT_CALLBACK_URL, PAYMENT_STATUS, SWISH_ERRORS };
