import { makeObservable, action, observable, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { getCart, verifyCart } from '../api/webshop';
import Money from '../constants/types/Money';
import { parseProduct } from '../api/requestParser/requestParser';

export default class CartStore {
  cartStorageKey = '';

  shopStore = null;

  items = [];

  cartItems = [];

  cartIsLoading = false;

  failedArticles = {};

  static makeCopy(store) {
    const newStore = new CartStore(store.shopStore);
    newStore.items = store.items.slice();

    return newStore;
  }

  constructor(shopStore) {
    this.shopStore = shopStore;

    this.cartStorageKey = `${this.shopStore.id}/cart`;

    makeObservable(this, {
      items: observable,
      cartItems: observable,
      cartIsLoading: observable,
      failedArticles: observable,
      articleCount: computed,
      sum: computed,
      sumNoVat: computed,
      vatSums: computed,
      sortedItems: computed,
      addItem: action,
      updateItem: action,
      loadFromStorage: action,
      clear: action,
    });
  }

  get articleCount() {
    return this.items.reduce((acc, item) => acc + item.count, 0);
  }

  get sum() {
    const sum = this.items.reduce((acc, item) => {
      return item.article?.price.multiply(item.count).add(acc);
    }, new Money(0));

    return sum;
  }

  get sumNoVat() {
    const sum = this.items.reduce(
      (acc, item) => item.article.priceNoVat.multiply(item.count).add(acc),
      new Money(0)
    );

    return sum;
  }

  get vatSums() {
    const sums = {};

    for (const item of this.items) {
      const { article } = item;
      const vatStr = article.tax.toString();

      if (!Object.keys(sums).includes(vatStr)) {
        sums[vatStr] = new Money(0);
      }

      sums[vatStr] = sums[vatStr].add(
        article.price.subtract(article.priceNoVat).multiply(item.count)
      );
    }

    return sums;
  }

  get sortedItems() {
    return this.items
      .slice()
      .sort((a, b) => (a.article?.title < b.article?.title ? -1 : 1));
  }

  checkIfItemIsInCart(articleId, description) {
    return this.items.find(
      (item) =>
        item.article.id.toString() === articleId.toString() &&
        item.description === description
    );
  }

  addItem(articleId, count = 1, description) {
    const article = this.shopStore.getArticleById(articleId);
    const storedArticle = this.checkIfItemIsInCart(articleId, description);

    if (storedArticle) {
      this.items = this.items.map((item) =>
        item.article.id === articleId && item.description === description
          ? {
              ...storedArticle,
              count: Math.min(item.count + count, article.stockRemaining),
            }
          : item
      );
    } else {
      this.items = this.items.concat([
        {
          id: uuidv4(),
          article,
          count: Math.min(count, article.stockRemaining),
          sizeOfItem: 1,
          description,
        },
      ]);
    }

    this.saveToStorage();
  }

  updateItem(id, newItem) {
    this.items = this.items.map((originalItem) =>
      originalItem.id === id ? { ...originalItem, ...newItem } : originalItem
    );

    this.items = this.items.filter((item) => item.count > 0);

    this.saveToStorage();
  }

  getItemByArticleId(articleId) {
    return this.items.find(
      (item) => item.article.id.toString() === articleId.toString()
    );
  }

  saveToStorage() {
    localStorage.setItem(
      this.cartStorageKey,
      JSON.stringify({
        time: Date.now(),
        items: this.items,
      })
    );
  }

  async getDbCart(cartKey) {
    this.cartIsLoading = true;
    const cart = await getCart(cartKey);

    this.item = cart.articles
      .map((element) => ({
        articleId: element.productKey,
        count: element.count,
      }))
      .filter((item) => this.shopStore.getArticleById(item.articleId));

    this.cartIsLoading = false;
  }

  async loadFromStorage() {
    this.cartIsLoading = true;
    let data = null;

    if (localStorage) {
      data = localStorage.getItem(this.cartStorageKey);
    }

    if (data) {
      const cartData = JSON.parse(data);
      const maxAgeMs = 3 * 60 * 60 * 1000; // 3h
      if (Date.now() - cartData.time < maxAgeMs) {
        this.cartItems = cartData.items.map((element) => {
          return { ...element, article: parseProduct(element.article) };
        });

        await this.shopStore.getWebshop().then(() => {
          cartData.items.forEach((element) => {
            if (!this.shopStore.getArticleById(element.article.id)) {
              this.cartItems = cartData.items.filter(
                (el) => el.article.id !== element.article.id
              );
            }
            element.article = parseProduct(element.article);
          });
          this.items = this.cartItems;
        });
      } else {
        localStorage.removeItem(this.cartStorageKey);
        this.items = [];
      }
    } else {
      this.items = [];
    }
    this.cartIsLoading = false;
  }

  clear() {
    this.items = [];
    this.saveToStorage();
  }

  async verifyCart() {
    this.failedArticles = await verifyCart(this.shopStore.id, this.items);

    if (Object.keys(this.failedArticles).length > 0) {
      return false;
    }

    return true;
  }
}
