import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCartStore, useShopStore } from '../stores';
import isColorDark from '../utils/isColorDark';

const ShopCartLine = observer(() => {
  const shopStore = useShopStore();
  const cartStore = useCartStore();
  const { t } = useTranslation();

  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';

  return (
    <>
      <div
        className="row shop-cart-line"
        style={{
          backgroundColor: shopStore.headerBackgroundColor,
          color: shopStore.headerTitleColor,
        }}
      >
        <div className="col col-xs-12 col-md-8 col-lg-6 mx-auto">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">{`${cartStore.items.length} ${
                  cartStore.items.length === 1
                    ? t('cartLine.article')
                    : t('cartLine.articles')
                }`}</div>
              </div>
              <div className="row">
                <div className="col">{`${cartStore.sum.format()}`}</div>
              </div>
            </div>
            <div className="col shop-cart-line-button-container">
              <div className="row mt-1">
                <div className="col">
                  {cartStore.articleCount > 0 && (
                    <Link
                      to={`/${shopStore.id}/cart`}
                      className="btn"
                      style={{
                        backgroundColor: shopStore.headerTitleColor,
                        border: 'none',
                        color: colorBasedOnBrightnessOfBackGround,
                      }}
                    >
                      <i
                        className="fas fa-shopping-cart pr-2"
                        style={{ color: colorBasedOnBrightnessOfBackGround }}
                      />
                      {t('cart.toCheckout')}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">{cartStore.sum.format()}</div>
        </div>
      </div>
    </>
  );
});

export default ShopCartLine;
