export const Countries = {
  SWEDEN: 'SWEDEN',
  FINLAND: 'FINLAND',
};

export const CountryName = {
  [Countries.SWEDEN]: 'Sverige',
  [Countries.FINLAND]: 'Suomi',
};

export const CountryShort = {
  [Countries.SWEDEN]: 'sv-SE',
  [Countries.FINLAND]: 'fi-FI',
};

export const CountryCurrency = {
  [Countries.SWEDEN]: 'SEK',
  [CountryShort.SWEDEN]: 'SEK',
  [Countries.FINLAND]: 'EUR',
  [CountryShort.FINLAND]: 'EUR',
};

export default CountryName;
