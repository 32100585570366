import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useCartStore } from '../stores';
import LoadingView from '../views/LoadingView';

const CartSummaryTable = observer(() => {
  const cartStore = useCartStore();
  const { t } = useTranslation();
  if (cartStore.cartIsLoading) {
    return <LoadingView text={t('common.loadingCart')} />;
  }

  return (
    <table className="table table-striped ">
      <thead>
        <tr>
          <th>{t('cart.product')}</th>
          <th>{t('cart.amount')}</th>
          <th>{t('cart.sum')}</th>
          <th>{t('cart.whereOfVat')}</th>
        </tr>
      </thead>
      <tbody>
        {cartStore?.sortedItems.map((item) => {
          const { article } = item;
          return (
            <tr key={article.id}>
              <td>{article.title}</td>
              <td>{item.count}</td>
              <td>{article.price.multiply(item.count).format()}</td>
              <td>
                {article.price
                  .subtract(article.priceNoVat)
                  .multiply(item.count)
                  .format()}
              </td>
            </tr>
          );
        })}
        <tr>
          <td aria-label="Empty" />
          <td aria-label="Empty" />
          <td>
            <strong>{cartStore.sum.format()}</strong>
          </td>
          <td>
            <strong>
              {cartStore.sum.subtract(cartStore.sumNoVat).format()}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export default CartSummaryTable;
