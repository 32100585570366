import { IS_STAGING } from '../environment';

const STRIPE_API_PUBLISHABLE_KEY_STAGING =
  'pk_test_51K8UCZHBndFkMYd3JZsZgjIt2ednmBX8QjtAU9k2k8CX0zLrobQ4XnFai8xf0CLA4NxaIogKhmSEvKBHUlMGG3PV00T2ktF1Xa';
const STRIPE_API_PUBLISHABLE_KEY_PROD =
  'pk_live_51K8UCZHBndFkMYd3mHn5bvi9YNzuda0PDZL4SdvS36iA2BFUThfcx0PXCdpXTUEHWv10uQYnJayaNyrtvW3EqxcB00Kn2g6JXK';

/* eslint-disable import/prefer-default-export */
export const STRIPE_API_PUBLISHABLE_KEY = IS_STAGING
  ? STRIPE_API_PUBLISHABLE_KEY_STAGING
  : STRIPE_API_PUBLISHABLE_KEY_PROD;
