import { observer } from 'mobx-react';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import { useShopStore } from '../stores';

const ReturnPolicyView = observer(() => {
  const shopStore = useShopStore();
  const { t } = useTranslation();

  return (
    <PageContainer shopStore={shopStore} title={t('payment.returnPolicy')}>
      <ReactMarkdown className="col col-xs-12 col-md-12 col-lg-8 mx-auto returnPolicy">
        {shopStore.webshopSettings.returnPolicy}
      </ReactMarkdown>
    </PageContainer>
  );
});
export default ReturnPolicyView;
