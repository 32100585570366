import React from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import ShopItemsContainer from '../components/ShopItemsContainer';
import ShopItem from '../components/ShopItem';
import { useShopStore } from '../stores';

const MainShopView = observer(() => {
  const shopStore = useShopStore();
  const { t } = useTranslation();

  if (!shopStore.id || (!shopStore.isLoading && shopStore.error)) {
    return (
      <div className="message-wrapper">
        <img src="error.svg" alt="Webshop" />
        <p>{t('error.pageNotFound')}</p>
      </div>
    );
  }

  if (!shopStore.active) {
    return (
      <div className="message-wrapper">
        <img src="webshop.svg" alt="Webshop" />
        <p>{t('error.shopNotOpen')}</p>
      </div>
    );
  }

  return (
    <PageContainer>
      <div className="container-fluid">
        <div
          className="row shop-description-container mt-4 p-3"
          style={{
            backgroundColor: shopStore.headerBackgroundColor,
            color: shopStore.headerTitleColor,
          }}
        >
          <div className="col-12">
            <span style={{ whiteSpace: 'pre-line' }}>
              {shopStore.description}
            </span>
          </div>
        </div>
      </div>
      <ShopItemsContainer>
        {shopStore.articles.map((article) => (
          <ShopItem article={article} key={article.id} />
        ))}
      </ShopItemsContainer>
    </PageContainer>
  );
});

export default MainShopView;
