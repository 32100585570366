import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShopStore } from '../stores';
import isColorDark from '../utils/isColorDark';
import { getUnitType } from '../utils/product';

const CountInputModal = observer(
  ({
    article,
    onSubmit,
    onCancel,
    customUnits,
    buttonTitle,
    articleCount,
    articleCountInCart,
  }) => {
    const shopStore = useShopStore();
    const [count, setCount] = useState(articleCount || 1);
    const [showAmountTooLowAlert, setShowAmountTooLowAlert] = useState(false);
    const [showAmountTooHighAlert, setShowAmountHighLowAlert] = useState(false);
    const [showPriceTooLowAlert, setShowPriceTooLowAlert] = useState(false);
    const { t } = useTranslation();
    const setClampedCount = (newCount) => {
      if (newCount.charAt(0) === '0' && newCount.length > 1) {
        if (newCount.charAt(1) !== '.') {
          return;
        }
      }
      if (Number(newCount) === 0) {
        setShowAmountTooLowAlert(false);
        setShowAmountHighLowAlert(false);
        setCount(newCount);
        return;
      }
      if (
        articleCountInCart
          ? Number(newCount + articleCountInCart) >
            Number(article.stockRemaining)
          : Number(newCount) > Number(article.stockRemaining)
      ) {
        setShowAmountHighLowAlert(true);
        setShowPriceTooLowAlert(false);

        setCount(newCount);
        return;
      }
      if (Number(newCount) < Number(article.minimumAmountAllowed)) {
        setShowAmountTooLowAlert(true);
        setShowPriceTooLowAlert(false);

        setCount(newCount);
        return;
      }
      if (article.price.amount * Number(newCount) < 10) {
        setShowPriceTooLowAlert(true);
        setCount(newCount);
        return;
      }
      setShowPriceTooLowAlert(false);
      setShowAmountTooLowAlert(false);
      setShowAmountHighLowAlert(false);
      setCount(newCount);
    };

    const handleBlur = () => {
      if (!count) {
        setCount(0);
      }
    };

    const submit = () => {
      if (count <= 0) {
        onCancel();
        return;
      }
      if (article.price.amount * count < 10) {
        setShowPriceTooLowAlert(true);
        return;
      }
      setShowAmountTooLowAlert(false);
      if (article.minimumAmountAllowed > count) {
        setShowAmountTooLowAlert(true);
        return;
      }
      if (
        articleCountInCart
          ? article.stockRemaining < count + articleCountInCart
          : article.stockRemaining < count
      ) {
        setShowAmountHighLowAlert(true);
        return;
      }

      if (onSubmit) {
        onSubmit(Number(count));
      }
    };

    const cancel = (event) => {
      if (event.target.className === 'count-input-modal') {
        setCount(0);

        if (onCancel) {
          onCancel();
        }
      }
    };

    const colorBasedOnBrightnessOfBackGround = isColorDark(
      shopStore.headerTitleColor
    )
      ? 'black'
      : 'white';
    return (
      <div
        className="count-input-modal"
        onClick={(event) => {
          cancel(event);
        }}
      >
        <div className="count-input-modal-container">
          <h2 className="count-input-modal-article-name">{article.title}</h2>
          <p>{`${article.price.format()}/ ${
            getUnitType(article.unitType, customUnits).pre
          }`}</p>
          <div className="form-group">
            <label>{`${t('inputModal.amount')} ${
              getUnitType(article.unitType, customUnits).unit
            }:`}</label>
            <input
              type="number"
              className="form-control"
              value={count}
              onChange={(event) => {
                setClampedCount(event.target.value);
              }}
              onBlur={() => {
                handleBlur();
              }}
            />
            <div className="countInputAlertContainer">
              <p className="countInputAlertTextPrice t">
                {showPriceTooLowAlert && t('inputModal.minPrice')}
                {showAmountTooHighAlert &&
                  t('inputModal.onlyXLeftInStock', {
                    stock: article.stockRemaining,
                    unit: getUnitType(article.unitType, customUnits).unit,
                  })}
                {showAmountTooLowAlert &&
                  t('inputModal.minAmount', {
                    amount: article.minimumAmountAllowed,
                    unit: getUnitType(article.unitType, customUnits).unit,
                  })}
              </p>
            </div>
          </div>

          <div className="form-group">
            <p>{`${t('inputModal.total')}: ${article.price
              .multiply(count)
              .format()}`}</p>
          </div>
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: shopStore.headerTitleColor,
              border: 'none',
              color: colorBasedOnBrightnessOfBackGround,
            }}
            onClick={() => {
              submit();
            }}
          >
            <i
              className="fas fa-cart-plus"
              style={{ color: colorBasedOnBrightnessOfBackGround }}
            />
            {buttonTitle || t('inputModal.addToCart')}
          </button>
        </div>
      </div>
    );
  }
);

export default CountInputModal;
