import React, { createContext, useContext } from 'react';

import AuthStore from './AuthStore';
import CardPaymentStore from './CardPaymentStore';
import CartStore from './CartStore';
import PaymentStore from './PaymentStore';
import ReceiptStore from './ReceiptStore';
import ShopStore from './ShopStore';

export class RootStore {
  constructor() {
    this.authStore = new AuthStore();
    this.shopStore = new ShopStore();
    this.cartStore = new CartStore(this.shopStore);
    this.paymentStore = new PaymentStore(this.shopStore, this.cartStore);
    this.cardPaymentStore = new CardPaymentStore(
      this.shopStore,
      this.cartStore,
      this.paymentStore
    );
    this.receiptStore = new ReceiptStore();
  }
}

export const StoreContext = createContext({});

export const StoreProvider = ({ store, children }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);

export const useAuthStore = () => useContext(StoreContext).authStore;

export const useShopStore = () => useContext(StoreContext).shopStore;

export const useCartStore = () => useContext(StoreContext).cartStore;

export const usePaymentStore = () => useContext(StoreContext).paymentStore;

export const useCardPaymentStore = () =>
  useContext(StoreContext).cardPaymentStore;

export const useReceiptStore = () => useContext(StoreContext).receiptStore;
