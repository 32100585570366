import React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import PageContainer from '../components/PageContainer';
import ShopArticleInfo from '../components/ShopArticleInfo';
import { useCartStore, useShopStore } from '../stores';

const ArticleView = observer(() => {
  const shopStore = useShopStore();
  const cartStore = useCartStore();
  const { articleId } = useParams();
  const article = shopStore.getArticleById(articleId);

  return (
    <PageContainer title={article.title}>
      <ShopArticleInfo
        article={article}
        customUnits={shopStore.customUnits}
        tags={shopStore.tags}
        onAddToCart={(articleId, count, description) => {
          cartStore.addItem(articleId, count, description);
        }}
      />
    </PageContainer>
  );
});

export default ArticleView;
