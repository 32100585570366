import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CountInputModal from './CountInputModal';
import { getUnitType, UnitTypes } from '../utils/product';
import { useCartStore, useShopStore } from '../stores';
import isColorDark from '../utils/isColorDark';
import ProductTagDots from './ProductTagDots';

const ShopItem = observer(({ article }) => {
  const shopStore = useShopStore();
  const cartStore = useCartStore();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const outOfStock = article.stockRemaining === 0;
  const { t } = useTranslation();

  const addToCart = () => {
    if (outOfStock) {
      return;
    }
    if (
      getUnitType(article.unitType, shopStore.customUnits).unit ===
      UnitTypes().DEFAULT.unit
    ) {
      cartStore.addItem(article.id, 1);
    } else {
      setShowModal(true);
    }
  };

  const modalSubmit = (count) => {
    cartStore.addItem(article.id, count);
    setShowModal(false);
  };

  const modalCancel = () => {
    setShowModal(false);
  };

  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';

  const renderImage = () => (
    <>
      <img
        className="shop-item-image"
        src={article.image}
        alt={article.title}
        onLoad={() => {
          setImageLoaded(true);
        }}
        style={{ display: imageLoaded ? 'flex' : 'none' }}
      />
      {!imageLoaded && (
        <div
          className="shop-item-image-placeholder"
          style={{ backgroundColor: shopStore.headerBackgroundColor }}
        >
          <i
            className="far fa-image"
            style={{ color: shopStore.headerTitleColor }}
          />
        </div>
      )}
    </>
  );

  return (
    <>
      <div
        className={`shop-item ${
          window.screen.width <= 320 ? 'col-12' : 'col-6'
        } col-sm-4 col-lg-3 col-xl-2`}
      >
        <div className="shop-item-content">
          <ProductTagDots articleTags={article.tags} />

          <Link to={`/${shopStore.id}/${article.id}`}>{renderImage()}</Link>
          <div className="row shop-item-bottom-row">
            <div className="col">
              <Link to={`/${shopStore.id}/${article.id}`}>
                <div className="row shop-item-details">
                  <div className="col shopItemNameDiv">{article.title}</div>
                </div>
                <div className="row shop-item-details">
                  <div className="col">
                    {outOfStock
                      ? t('product.soldOut')
                      : `${article.price.format()}/ ${
                          getUnitType(article.unitType, shopStore.customUnits)
                            .pre
                        }`}
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="shop-item-add-button pl-2 pr-4"
              style={{
                backgroundColor: shopStore.headerTitleColor,
                color: colorBasedOnBrightnessOfBackGround,
              }}
              onClick={addToCart}
            >
              <span
                className={`shop-item-add-button-content ${
                  outOfStock ? 'out-of-stock' : ''
                }`}
              >
                +
              </span>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <CountInputModal
          article={article}
          onSubmit={modalSubmit}
          onCancel={modalCancel}
          customUnits={shopStore.customUnits}
          articleCountInCart={
            cartStore.getItemByArticleId(article.id)
              ? cartStore.getItemByArticleId(article.id).count
              : 0
          }
        />
      )}
    </>
  );
});
export default ShopItem;
