import DiscountTypes from '../constants/discounts';

export const calculateTotal = (product, discount = undefined) => {
  let productTotal = product.price
    .multiply(product.count || 1)
    .multiply(product.sizeOfItem || 1);

  if (product.discount) {
    productTotal = productTotal.subtract(
      calculateDiscountAmount(productTotal, product.discount)
    );
  }

  if (discount) {
    productTotal = productTotal.subtract(
      calculateDiscountAmount(productTotal, discount)
    );
  }

  return productTotal;
};

export const calculateDiscountAmount = (total, discount) => {
  if (!discount) {
    return 0;
  }
  let discountAmount = 0;

  if (discount.type === DiscountTypes.PERCENTAGE) {
    discountAmount = total.multiply(discount.amount / 100);
  } else if (discount.type === DiscountTypes.AMOUNT) {
    discountAmount = discount.amount;
  }

  return discountAmount.amount >= 0 ? discountAmount : 0;
};

export const getVatMultiplier = (vatPct) => {
  return 1 + vatPct / 100;
};

export const getVatFromWithVat = (priceWithVat, vatPct) => {
  const priceNoVat = Math.round(priceWithVat.amount / getVatMultiplier(vatPct));

  return priceWithVat.subtract(priceNoVat);
};
