const PAYMENT_SOURCE = {
  WEBSHOP: 'WEBSHOP',
};

const PAY_METHODS = {
  SWISH_TRADE: 'Swishhandel',
  CARD: 'Kort',
};

const ALLOWED_PAYMENTMETHODS = ['card', 'swishTrade', 'swish'];
const NO_PAYMENT_STARTED = '-1';

const PAYMENTMETHOD_CONFIG_TYPES = {
  SWISH_TRADE: 'SWISH_TRADE',
  SWISH_COMPANY: 'SWISH_COMPANY',
  SWISH_TEST: 'SWISH_TEST',
  STRIPE: 'STRIPE',
  CASH: 'CASH',
  EXTERNAL_CARD_MACHINE: 'EXTERNAL_CARD_MACHINE',
  TIDYPAY: 'TIDYPAY',
  OTHER: 'OTHER',
};

export {
  PAY_METHODS,
  PAYMENT_SOURCE,
  NO_PAYMENT_STARTED,
  ALLOWED_PAYMENTMETHODS,
  PAYMENTMETHOD_CONFIG_TYPES,
};
