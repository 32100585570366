import React from 'react';

import firebase from 'firebase/app';
import MainRouter from './routers/MainRouter';

import 'firebase/firestore';
import 'firebase/functions';

import './style/style.scss';
import { IS_STAGING } from './environment';
import { RootStore, StoreProvider } from './stores';
import initI18n from './utils/i18next';

const STORE_LOAD_ROUTE_BLACKLIST = [
  'appinfo',
  'auth',
  'card',
  'integrateFortnox',
  'integrateVisma',
];

const firebaseConfigProd = {
  apiKey: 'AIzaSyAXM-XlUZwf7DoBSBq-pW_WF4OCZDOPDvw',
  authDomain: 'enkla-kassan.firebaseapp.com',
  databaseURL: 'https://enkla-kassan.firebaseio.com',
  projectId: 'enkla-kassan',
  storageBucket: 'enkla-kassan.appspot.com',
  // messagingSenderId: "57452537691",
  appId: '1:57452537691:web:77e24f241d6abda9d7dc7e',
  measurementId: 'G-N2QC03N7KC',
};
const firebaseConfigStaging = {
  apiKey: 'AIzaSyDXR_wCYX78Zpjio2xOqJTAL8b2xS9dtAM',
  authDomain: 'enklakassan-staging.firebaseapp.com',
  projectId: 'enklakassan-staging',
  storageBucket: 'enklakassan-staging.appspot.com',
  // messagingSenderId: "302380194563",
  appId: '1:302380194563:web:548d8f02451d09c2f7a736',
};

firebase.initializeApp(IS_STAGING ? firebaseConfigStaging : firebaseConfigProd);

const shopId = window.location.pathname.split('/')[1];
const store = new RootStore();
initI18n();

if (!STORE_LOAD_ROUTE_BLACKLIST.includes(shopId)) {
  store.shopStore.load(shopId).then(() => {
    store.cartStore.loadFromStorage();
  });
}

function App() {
  return (
    <div className="app">
      <StoreProvider store={store}>
        <MainRouter />
      </StoreProvider>
    </div>
  );
}

export default App;
