import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Money from '../constants/types/Money';

const ReceiptSumTable = observer(({ receiptStore }) => {
  const { t } = useTranslation();
  const getVatSums = () => {
    const vatSums = {};

    for (const item of receiptStore.articles) {
      if (!vatSums[item.article.tax]) {
        vatSums[item.article.tax] = new Money(0);
      }

      vatSums[item.article.tax] = vatSums[item.article.tax].add(
        item.price.subtract(item.priceNoVat).multiply(item.count)
      );
    }

    return vatSums;
  };

  const getSums = () =>
    receiptStore.articles.reduce(
      (acc, article) => ({
        sum: acc.sum.add(article.price.multiply(article.count)),
        sumNoVat: acc.sumNoVat.add(article.priceNoVat.multiply(article.count)),
      }),
      { sum: new Money(0), sumNoVat: new Money(0) }
    );

  const renderVatLines = () => {
    const vatSums = getVatSums();
    const sortedKeys = Object.keys(vatSums).sort((a, b) =>
      a.toString().padStart(2, '0') < b.toString().padStart(2, '0') ? -1 : 1
    );

    const headerRow = (
      <tr key="moms">
        <td>{t('cart.vat')}:</td>
        <td aria-label="Empty" />
      </tr>
    );

    return [headerRow].concat(
      sortedKeys.map((vatKey) => (
        <tr key={vatKey}>
          <td className="cart-tax-cell">
            {vatKey}
            %:
          </td>
          <td className="text-right">{vatSums[vatKey].format()}</td>
        </tr>
      ))
    );
  };

  const sums = getSums();

  return (
    <table className="table table-sm cart-sum-table table-borderless">
      <tbody>
        {renderVatLines()}
        <tr>
          <td>{t('cart.sumExVat')}:</td>
          <td className="text-right">{sums.sumNoVat.format()}</td>
        </tr>
        <tr className="cart-sum-row">
          <td>{t('cart.sum')}</td>
          <td className="text-right">{sums.sum.format()}</td>
        </tr>
      </tbody>
    </table>
  );
});

export default ReceiptSumTable;
