import React from 'react';
import { observer } from 'mobx-react';
import { getUnitType } from '../utils/product';
import { useShopStore } from '../stores';

const ReceiptTable = observer(({ receiptStore }) => {
  const shopStore = useShopStore();

  const renderRows = () =>
    receiptStore.articles.map((article) => (
      <tr key={article.productKey}>
        <td>{article.title}</td>
        <td>
          {article.count}
          {getUnitType(article.unitType, shopStore.customUnits).pre}
        </td>
        <td className="receipt-table-item-sum-cell">
          {article.price.multiply(article.count).format()}
        </td>
      </tr>
    ));

  return (
    <table className="table table-sm table-borderless">
      <tbody>{renderRows()}</tbody>
    </table>
  );
});

export default ReceiptTable;
