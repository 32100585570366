import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import CartSummaryTable from '../components/CartSummaryTable';
import { useCartStore, usePaymentStore, useShopStore } from '../stores';
import WebshopSwishPayment from '../components/WebshopSwishPayment';
import WebshopCardPayment from '../components/WebshopCardPayment';
import Accordion from '../components/Accordion';
import swishLogo from '../assets/Swish-logo.png';
import stripeLogo from '../assets/stripe-logo.png';
import isColorDark from '../utils/isColorDark';
import { PAYMENTMETHOD_CONFIG_TYPES } from '../constants/payment';

const PaymentView = observer(() => {
  const paymentStore = usePaymentStore();
  const shopStore = useShopStore();
  const cartStore = useCartStore();
  const history = useHistory();
  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const verifyCart = async () => {
      const cartOk = await cartStore.verifyCart();
      if (!cartOk) {
        history.replace(`/${shopStore.id}/cartupdate`);
      }
    };
    verifyCart();
  });

  if (cartStore.articleCount === 0) {
    return (
      <PageContainer title={t('payment.payment')} hideCartLine>
        <div className="col col-xs-12 col-md-12 col-lg-8 mx-auto m-5 d-flex align-items-center flex-column">
          <p>{t('cart.cartEmpty')}</p>
          <Link
            to={`/${shopStore.id}`}
            className="btn  btn-block"
            style={{
              backgroundColor: shopStore.headerTitleColor,
              border: 'none',
              color: colorBasedOnBrightnessOfBackGround,
            }}
          >
            {t('cart.backToShop')}
          </Link>
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer title={t('payment.payment')} hideCartLine>
      <div className="row">
        <div className="col col-xs-12 col-md-8 col-lg-6 mx-auto">
          <div className="row">
            <div className="col mb-3">
              <CartSummaryTable />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>{t('payment.deliveryInfo')}:</label>
                <input
                  type="text"
                  id="info"
                  className={`form-control ${
                    paymentStore.nameError ? 'is-invalid' : ''
                  }`}
                  onChange={(evt) => {
                    paymentStore.setExtraInformation(evt.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  {t('payment.deliveryInfoText')}
                </small>
              </div>

              <div className="form-group">
                <label>{`${t('payment.yourName')}: ${
                  shopStore.requireName ? '*' : ''
                }`}</label>
                <input
                  type="text"
                  autoComplete="name"
                  id="name"
                  className="form-control"
                  value={paymentStore.name}
                  onChange={(evt) => {
                    paymentStore.setName(evt.target.value);
                  }}
                />
                {paymentStore.nameError && (
                  <small className="form-text text-danger">
                    {paymentStore.nameError}
                  </small>
                )}
              </div>
              {shopStore.requirePhone && (
                <div className="form-group">
                  <label>{t('payment.yourPhone')}: *</label>
                  <input
                    type="tel"
                    autoComplete="phone"
                    id="phone"
                    className={`form-control ${
                      paymentStore.phoneError ? 'is-invalid' : ''
                    }`}
                    value={paymentStore.phone}
                    onChange={(evt) => {
                      paymentStore.setPhone(evt.target.value);
                    }}
                  />
                  {paymentStore.phoneError && (
                    <small className="form-text text-danger">
                      {paymentStore.phoneError}
                    </small>
                  )}
                </div>
              )}
              <div className="form-group">
                <label>{`${t('payment.yourEmail')}: ${
                  shopStore.requireEmail ? '*' : ''
                }`}</label>
                <input
                  type="email"
                  autoComplete="email"
                  id="email"
                  className={`form-control ${
                    paymentStore.emailError ? 'is-invalid' : ''
                  }`}
                  value={paymentStore.email}
                  onChange={(evt) => {
                    paymentStore.setEmail(evt.target.value);
                  }}
                />
                {!paymentStore.emailError && (
                  <small className="form-text text-muted">
                    {t('payment.emailNeeded')}
                  </small>
                )}
                {paymentStore.emailError && (
                  <small className="form-text text-danger">
                    {paymentStore.emailError}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div>
            {Object.keys(shopStore.activePayMethods).length > 0 ? (
              Object.entries(shopStore.activePayMethods).map(
                ([index, payMethod]) => {
                  if (
                    payMethod.type === PAYMENTMETHOD_CONFIG_TYPES.SWISH_TRADE
                  ) {
                    return (
                      <Accordion
                        title="Swish"
                        index={index}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        imageSource={swishLogo}
                        redioButton={
                          Object.keys(shopStore.activePayMethods).length > 1
                        }
                      >
                        <WebshopSwishPayment payMethod={payMethod} />
                      </Accordion>
                    );
                  }
                  if (payMethod.type === PAYMENTMETHOD_CONFIG_TYPES.STRIPE) {
                    return (
                      <Accordion
                        title={t('card.card')}
                        index={index}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        imageSource={stripeLogo}
                        redioButton={
                          Object.keys(shopStore.activePayMethods).length > 1
                        }
                      >
                        <WebshopCardPayment payMethod={payMethod} />
                      </Accordion>
                    );
                  }
                  return <div />;
                }
              )
            ) : (
              <div>{t('payment.noPaymentMethodsFound')}</div>
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
});

export default PaymentView;
