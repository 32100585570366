import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';
import { integrateFortnox, integrateVisma } from '../api/webshop';
import LoadingView from './LoadingView';

const NavigateToAppView = () => {
  const url = window.location.href;
  const parsedUrl = new URL(url);
  const queryParams = new URLSearchParams(parsedUrl.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isIntegratingVisma = window.location.pathname === '/integrateVisma';
  const isIntegratingFortnox = window.location.pathname === '/integrateFortnox';
  const { t } = useTranslation();
  const navigateToApp = () => {
    let url = `enklakassan://app.enklakassan.nu${window.location.pathname}`;
    if (window.location.pathname === '/integrateFortnox') {
      url = `enklakassan://fortnox.enklakassan.nu${window.location.pathname}${window.location.search}`;
    }
    if (window.location.pathname === '/integrateVisma') {
      url = `enklakassan://visma.enklakassan.nu${window.location.pathname}${window.location.search}`;
    }
    window.location = url;
  };
  useEffect(() => {
    const integrate = async () => {
      setIsLoading(true);
      if (code && state) {
        try {
          const decodedState = JSON.parse(decodeURIComponent(state));
          if (decodedState.isWeb) {
            if (!isIntegratingFortnox) {
              await integrateVisma(
                decodedState.companyID,
                code,
                decodedState.scopeState
              )
                .then(() => {
                  setMessage(t('integrat.vismaIntegrationSuccess'));
                })
                .catch((e) => {
                  Sentry.captureException(e, {
                    extra: {
                      code,
                      state,
                      url,
                    },
                  });
                  if (e.name) {
                    setErrorMessage(
                      `${t('integrat.vismaIntegrationFailed')}: ${e.name}`
                    );
                  } else {
                    setErrorMessage(t('integrat.vismaIntegrationFailed'));
                  }
                });
            } else {
              await integrateFortnox(
                decodedState.companyID,
                code,
                decodedState.scopeState
              )
                .then(() => {
                  setMessage(t('integrat.fortnoxIntegrationSuccess'));
                })
                .catch((e) => {
                  Sentry.captureException(e, {
                    extra: {
                      code,
                      state,
                      url,
                    },
                  });
                  if (e.name) {
                    setErrorMessage(
                      `${t('integrat.vismaIntegrationFailed')}: ${e.name}`
                    );
                  } else {
                    setErrorMessage(t('integrat.vismaIntegrationFailed'));
                  }
                });
            }
          } else {
            navigateToApp();
          }
        } catch {
          navigateToApp();
        }
      }
      setIsLoading(false);
    };
    integrate();
  }, [isIntegratingVisma, code, state, url, isIntegratingFortnox, t]);
  if (isLoading) {
    return <LoadingView text={t('common.loading')} />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center text-md-left">
          <img
            src={logo}
            className="my-4"
            alt="applogo"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          {message && <div className="alert alert-success">{message}</div>}
          <p className="mb-4">
            Effektivisera din verksamhet med vår sömlösa bokföringsintegration
          </p>

          <a
            href="https://enka.se/fortnox"
            className="btn btn-success"
            role="button"
          >
            Läs mer om bokföring med Enklakassan
          </a>
        </div>
      </div>
    </div>
  );
};
export default NavigateToAppView;
