import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import svTranslation from '../locales/sv.json';
import enTranslation from '../locales/en.json';

const prodSettings = {
  fallbackLng: 'sv',
  supportedLngs: ['sv', 'en'],
  ns: ['translation'],
  defaultNS: 'translation',

  resources: {
    sv: {
      translation: svTranslation,
    },
    en: {
      translation: enTranslation,
    },
  },

  interpolation: {
    escapeValue: false,
  },
};

const initI18n = () => {
  i18next
    .use(LanguageDetector) // Add the language detector
    .use(initReactI18next)
    .init(prodSettings);
};

export default initI18n;
