import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';

const PasswordResetView = () => {
  const [oobCodeValid, setOobCodeValid] = useState(true);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [newPassword2Error, setNewPassword2Error] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get('oobCode');
  const { t } = useTranslation();

  useEffect(() => {
    firebase
      .auth()
      .verifyPasswordResetCode(oobCode)
      .then((oobCodeEmail) => {
        setEmail(oobCodeEmail);
      })
      .catch(() => {
        setOobCodeValid(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = () => {
    let passwordError = '';
    let password2Error = '';

    if (newPassword.length < 6) {
      passwordError = t('passwordReset.passwordTooShort');
    }

    if (newPassword !== newPassword2) {
      password2Error = t('passwordReset.validationNotMatching');
    }

    setNewPasswordError(passwordError);
    setNewPassword2Error(password2Error);

    return !passwordError && !password2Error;
  };

  const handlePasswordChange = () => {
    if (validate()) {
      setLoading(true);
      setConfirmError('');

      firebase
        .auth()
        .confirmPasswordReset(oobCode, newPassword)
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          if (error.code === 'auth/weak-password') {
            setConfirmError(t('passwordReset.passwordTooWeak'));
          } else {
            setConfirmError(t('error.anErrorOccurred'));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const renderForm = () => (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="input-newpassword">
              {t('passwordReset.newPassword')}
            </label>
            <input
              type="password"
              className="form-control"
              id="input-newpassword"
              onChange={(evt) => setNewPassword(evt.target.value)}
            />
            <small className="form-text text-muted">
              {t('passwordReset.minLength')}
            </small>
            {newPasswordError && (
              <span className="text-danger">{newPasswordError}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="input-newpassword2">
              {t('passwordReset.repeatPassword')}
            </label>
            <input
              type="password"
              className="form-control"
              id="input-newpassword2"
              onChange={(evt) => setNewPassword2(evt.target.value)}
            />
            {newPassword2Error && (
              <span className="text-danger">{newPassword2Error}</span>
            )}
          </div>
          <button
            type="button"
            className="btn btn-primary mt-2"
            style={{ minWidth: 120 }}
            onClick={() => handlePasswordChange()}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm text-light" />
            ) : (
              t('passwordReset.changePassword')
            )}
          </button>
          {confirmError && (
            <div className="text-danger mt-3">{confirmError}</div>
          )}
        </div>
      </div>
    </>
  );

  const renderInvalidOobCode = () => <p>{t('passwordReset.linkExpired')}</p>;

  const renderSuccess = () => (
    <p>{t('passwordReset.passwordHasBeenChanged', { email })}</p>
  );

  const renderNonSuccess = () =>
    oobCodeValid ? renderForm() : renderInvalidOobCode();

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12">
          <div className="card border">
            <div className="card-header">
              <h2 className="mb-0">{t('passwordReset.ekPasswordReset')}</h2>
            </div>
            <div className="card-body">
              {success ? renderSuccess() : renderNonSuccess()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetView;
