import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useCartStore, useShopStore } from '../stores';
import isColorDark from '../utils/isColorDark';
import { getUnitType } from '../utils/product';
import CountInputModal from './CountInputModal';

const CartTableRow = observer(({ item }) => {
  const shopStore = useShopStore();
  const cartStore = useCartStore();
  const { article } = item;
  const { t } = useTranslation();
  const [count, setCount] = useState(
    (item.count || 1) * (item.sizeOfItem || 1)
  );
  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';
  const [showModalInputUnits, setShowModalInputUnits] = useState(false);

  const updateCartCount = (value) => {
    cartStore.updateItem(item.id, { ...item, count: value || 0 });
  };

  const setClampedCount = (newCount) => {
    if (Number(newCount) > Number(article.stockRemaining)) {
      setCount(article.stockRemaining);
      updateCartCount(article.stockRemaining);
      return;
    }
    setCount(newCount);
    updateCartCount(newCount);
  };

  return (
    <>
      <tr key={item.id}>
        <td className="cart-article-name">
          {article.title}
          <p className="mt-3">{`${article.price.format()}/${
            getUnitType(article.unitType, shopStore.customUnits).pre
          }`}</p>
          {item.description && (
            <p className="mt-3">
              {t('cart.extraInfo')}: {item.description}
            </p>
          )}
        </td>
        <td>
          <div className="cart-table-sum-col">
            <div>
              <button
                type="button"
                className="btn btn-sm"
                style={{
                  backgroundColor: shopStore.headerTitleColor,
                  border: 'none',
                  color: colorBasedOnBrightnessOfBackGround,
                }}
                onClick={() => {
                  setClampedCount(count - 1);
                }}
              >
                -
              </button>
              <button
                type="button"
                className="form-control cart-count-input"
                onClick={() => setShowModalInputUnits(true)}
              >
                {count}
              </button>
              <button
                type="button"
                className="btn btn-sm"
                style={{
                  backgroundColor: shopStore.headerTitleColor,
                  border: 'none',
                  color: colorBasedOnBrightnessOfBackGround,
                }}
                onClick={() => {
                  setClampedCount(count + 1);
                }}
              >
                +
              </button>
            </div>
            <p className="cart-article-price">
              {article.price.multiply(item.count).format()}
            </p>
          </div>
        </td>
      </tr>
      {showModalInputUnits && (
        <CountInputModal
          article={article}
          onSubmit={(count) => {
            setClampedCount(count);
            setShowModalInputUnits(false);
          }}
          onCancel={() => setShowModalInputUnits(false)}
          customUnits={shopStore.customUnits}
          buttonTitle={t('cart.updateProduct')}
          articleCount={count}
        />
      )}
    </>
  );
});

const CartTable = observer(() => {
  const cartStore = useCartStore();
  const { t } = useTranslation();

  return (
    <table className="table table-striped cart-table">
      <thead>
        <tr>
          <th>{t('cart.product')}</th>
          <th className="cart-table-sum-header">{t('cart.sum')}</th>
        </tr>
      </thead>
      <tbody>
        {cartStore.sortedItems.map((item) => (
          <CartTableRow item={item} key={item.id} />
        ))}
      </tbody>
    </table>
  );
});

export default CartTable;
