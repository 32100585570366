import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';

const DownLoadFileView = () => {
  const [url, seturl] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    seturl(queryParams.get('url'));
  }, [url]);
  return (
    <div className="d-flex justify-content-center w-100 h-100">
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src={logo} className="rounded" alt="applogo" width="40%" />
        {url && (
          <a className="btn btn-block btn-primary mt-3 w-50" href={url}>
            {t('payment.downloadReceipt')}
          </a>
        )}
      </div>
    </div>
  );
};
export default DownLoadFileView;
