import React from 'react';
import { observer } from 'mobx-react';

import ShopHeader from './ShopHeader';
import ShopCartLine from './ShopCartLine';

const PageContainer = observer(({ hideCartLine, title, children }) => (
  <div className="page-container container-fluid">
    <ShopHeader />
    {title && (
      <div className="row">
        <div className="col col-2 col-lg-3 d-none d-md-block" />
        <div className="col mt-2">
          <h2 className="page-title">{title}</h2>
        </div>
      </div>
    )}
    {children}
    {!hideCartLine && <ShopCartLine />}
  </div>
));

export default PageContainer;
