import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import CardForm from '../components/CardForm';
import { useCardPaymentStore } from '../stores';
import { STRIPE_API_PUBLISHABLE_KEY } from '../constants/apiKeys';

const CardPayment = observer(() => {
  const route = useRouteMatch();
  const { clientSecret } = route.params;
  const cardPaymentStore = useCardPaymentStore();
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (clientSecret) {
      cardPaymentStore.fetch(clientSecret);
    }
  }, [clientSecret, cardPaymentStore]);

  useEffect(() => {
    if (cardPaymentStore.stripeAccountId || cardPaymentStore.isTest) {
      setStripePromise(
        loadStripe(STRIPE_API_PUBLISHABLE_KEY, {
          locale: 'sv',
          stripeAccount: cardPaymentStore.isTest
            ? undefined
            : cardPaymentStore.stripeAccountId,
        })
      );
    }
  }, [cardPaymentStore.stripeAccountId, cardPaymentStore.isTest]);

  const renderLoading = () => (
    <div className="w-100 mt-5 text-center">
      <div className="spinner-border text-primary" />
    </div>
  );

  const renderForm = () => (
    <div>
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <CardForm renderCart />
      </Elements>
    </div>
  );

  return cardPaymentStore.isLoading ? renderLoading() : renderForm();
});

export default CardPayment;
