import firebase from 'firebase/app';
import { isMobile } from 'react-device-detect';

const REGION = 'europe-west1';

export default function functions() {
  const functionsInstance = firebase.app().functions(REGION);

  if (import.meta.env.NODE_ENV === 'development') {
    const ip = isMobile ? 'YOUR_IP_HERE' : 'localhost';
    const functionsEmulatorUrl = `http://${ip}:5002`;
    if (ip === 'YOUR_IP_HERE') {
      console.warn(
        'LOCALHOST DOES NOT POINT TO THE FUNCTIONS EMULATOR WHEN RUNNING ON DEVICE, MANUALLY ADD IP TO functions.js'
      );
    }
    functionsInstance.useFunctionsEmulator(functionsEmulatorUrl);
  }

  return functionsInstance;
}
