import { makeObservable, observable, action, computed } from 'mobx';
import { isMobile } from 'react-device-detect';
import firebase from 'firebase';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import {
  SWISH_PAYMENT_CALLBACK_URL,
  PAYMENT_STATUS,
  SWISH_ERRORS,
} from '../constants/swish';
import amountStrToSwishFormat from '../utils/amountStrToSwishFormat';
import PriceTypes from '../constants/priceTypes';
import { PAYMENT_SOURCE, PAY_METHODS } from '../constants/payment';
import CART_STATUS from '../constants/cart';
import { createOrUpdateCart, createSwishPaymentRequest } from '../api/webshop';
import WEBSHOP_MODES from '../constants/shopModes';

export default class PaymentStore {
  shopStore = null;

  phone = '';

  email = '';

  name = '';

  extraInformation = '';

  paymentStatus = null;

  dbCartKey = null;

  paymentId = '';

  phoneError = '';

  emailError = '';

  nameError = '';

  swishError = '';

  orderId = '';

  swishUrl = '';

  constructor(shopStore, cartStore) {
    this.shopStore = shopStore;
    this.cartStore = cartStore;

    makeObservable(this, {
      phone: observable,
      email: observable,
      name: observable,

      paymentStatus: observable,
      paymentId: observable,
      dbCartKey: observable,

      phoneError: observable,
      emailError: observable,
      nameError: observable,
      swishUrl: observable,

      phoneWithCountryCode: computed,

      setPhone: action,
      setEmail: action,
      setName: action,
      clear: action,
      startPayment: action,
      listenForPayment: action,

      validateName: action,
      validatePhone: action,
      validateEmail: action,
    });
  }

  get phoneWithCountryCode() {
    if (this.phone.length === 0) {
      return this.phone;
    }

    // Adapt phoneNr to swish E-Commerce format
    let phoneWithCountryCode = this.phone.replace(/(\+)/, '');
    if (phoneWithCountryCode.charAt(0) === '0') {
      phoneWithCountryCode = `46${phoneWithCountryCode.substring(
        1,
        phoneWithCountryCode.length
      )}`;
    }

    return phoneWithCountryCode;
  }

  setPhone(phone) {
    this.phone = phone;
  }

  setEmail(email) {
    this.email = email;
  }

  setName(name) {
    this.name = name;
  }

  setExtraInformation(info) {
    this.extraInformation = info;
  }

  setSwishError(error) {
    this.swishError = error;
  }

  clear() {
    this.phone = '';
    this.email = '';

    this.paymentId = '';

    this.phoneError = '';
    this.emailError = '';
    this.paymentStatus = null;
    this.dbCartKey = null;
    this.orderId = null;
  }

  async startPayment(paymentMethod) {
    if (this.validatePhone() && this.validateName() && this.validateEmail()) {
      let paymentReference = null;

      const dbCartData = await this.createOrUpdateDbCart(paymentMethod);
      this.dbCartKey = dbCartData.key;
      if (!this.dbCartKey) {
        Sentry.captureException(new Error('Failed to create cart.'));
        throw new Error(i18next.t('error.anErrorOccurred'));
      }

      paymentReference = await this.getSwishPaymentReference(
        this.cartStore,
        paymentMethod.number,
        paymentMethod
      );
      if (paymentReference.error) {
        if (paymentReference.error in SWISH_ERRORS) {
          throw new Error(i18next.t(`swish.error.${paymentReference.error}`));
        }
        Sentry.captureException(
          new Error(
            `Failed to get payment reference: ${paymentReference.error}`
          )
        );
        throw new Error(i18next.t('error.anErrorOccurred'));
      }

      this.paymentId = paymentReference.pid;

      if (isMobile && !this.shopStore.isSwishTestActive) {
        const { paymentRequestToken } = paymentReference;
        if (!paymentRequestToken) {
          Sentry.captureException(
            new Error('Failed to get payment request token.')
          );
          throw new Error(i18next.t('error.anErrorOccurred'));
        }

        const rawCallbackUrl = `${window.location.origin}/${this.shopStore.id}/payment/${paymentReference.pid}`;
        const callbackUrl = encodeURIComponent(rawCallbackUrl);
        const swishUrl = `swish://paymentrequest?token=${paymentRequestToken}&callbackurl=${callbackUrl}`;
        this.swishUrl = swishUrl;
        window.location.replace(swishUrl);
      }
      this.listenForPayment(paymentReference.pid);
    } else {
      throw new Error('Validation error.');
    }
  }

  async getSwishPaymentReference(cartStore, number, paymentMethod) {
    const swishFormattedAmount = amountStrToSwishFormat(
      cartStore.sum.amount.toString()
    );
    const products = cartStore.items.map((item) => {
      const itemWithData = item.article;

      return {
        count: item.count,
        id: itemWithData.id,
        price: itemWithData.price,
        priceType: PriceTypes.STATIC,
        tax: itemWithData.tax,
        title: itemWithData.title,
        type: 'produkt',
        unitType: itemWithData.unitType,
        sizeOfItem: item.sizeOfItem,
      };
    });

    const paymentReference = await createSwishPaymentRequest({
      callbackUrl: SWISH_PAYMENT_CALLBACK_URL,
      payeePaymentReference: this.shopStore.company.key,
      payeeAlias: number,
      amount: swishFormattedAmount,
      currency: this.shopStore.swishCurrency,
      message: this.shopStore.swishMessage,
      ...(this.shopStore.isSwishTestActive && {
        useTestAPI: true,
      }),
      ...(!isMobile && {
        // E-Commerce / Webshop used by browser on computer
        payerAlias: this.phoneWithCountryCode,
      }),
      // Used by swishCallback
      phone: this.phone.trim(),
      email: this.email.trim(),
      products: products?.map((product) => ({
        ...product,
        product,
      })),
      source: PAYMENT_SOURCE.WEBSHOP,
      sourceData: {
        cartKey: this.dbCartKey,
      },
      paymentMethod,
      customerReference: null,
    }).catch((res) => {
      if (res.errorCode) {
        this.status = PAYMENT_STATUS.ERROR;
        this.swishError = res.name;
        throw new Error(res.name);
      }
      return res;
    });

    return paymentReference;
  }

  async createOrUpdateDbCart(paymentMethod) {
    return createOrUpdateCart(this.shopStore.id, {
      created: new Date().toISOString(),
      status: CART_STATUS.CREATED,
      companyKey: this.shopStore.company.key,
      shopKey: this.shopStore.id,
      paymentId: this.paymentId,
      payMethod: PAY_METHODS.SWISH_TRADE,
      paymentMethod,
      customerPhone: this.phone.trim(),
      customerEmail: this.email.trim(),
      customerName: this.name.trim(),
      extraInformation: this.extraInformation,
      articles: this.cartStore.items.map((item) => {
        const { article } = item;
        return {
          id: article.id,
          article: item.article,
          title: article.title,
          priceNoVat: article.priceNoVat,
          price: article.price,
          tax: article.tax,
          count: item.count,
          unitType: article.unitType,
          description: item.description,
          priceType: article.priceType,
        };
      }),
    });
  }

  listenForPayment(paymentId) {
    const stopListening = firebase
      .firestore()
      .collection('swishPayments')
      .doc(paymentId)
      .onSnapshot((snap) => {
        const data = snap.data();
        if (!data) {
          // Observing a payment that does not exist yet
          return;
        }
        this.dbCartKey = data.sourceData.cartKey;
        this.paymentStatus = data.status;
        switch (data.status) {
          case PAYMENT_STATUS.PAID:
            this.orderId = data.orderId;
            this.cartStore.clear();
            stopListening();
            break;

          case PAYMENT_STATUS.DECLINED:
          case PAYMENT_STATUS.ERROR:
            stopListening();
            break;

          default:
            break;
        }
      });
  }

  validateName() {
    if (this.shopStore.requireName) {
      if (this.name.length <= 3 || this.name === '') {
        this.nameError = i18next.t('payment.enterAValidName');
        return false;
      }
    }

    this.nameError = '';
    return true;
  }

  validateEmail() {
    if (this.shopStore.requireEmail && this.email === '') {
      this.emailError = i18next.t('payment.enterAValidEmail');
      return false;
    }

    if (
      /^.+@.+\..+$/.test(this.email) ||
      this.shopStore.shopMode !== WEBSHOP_MODES.WEBSHOP ||
      this.email === ''
    ) {
      this.emailError = '';
      return true;
    }
    this.emailError = i18next.t('payment.validateEmail');
    return false;
  }

  validatePhone() {
    if (this.shopStore.requirePhone) {
      this.phoneError =
        this.phone.match(/^\+?[0-9\s-]+$/) &&
        this.phone.match(/[0-9]/g).length >= 7
          ? ''
          : i18next.t('payment.validatePhone');

      // if (!isMobile && !this.phoneError) {
      //   if (this.phoneWithCountryCode.slice(0, 2) !== '46') {
      //     this.phoneError = 'Kontrollera att mobilnumret är korrekt.';
      //   }
      // }
      return !this.phoneError;
    }
    return true;
  }
}
