import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import ReceiptTable from '../components/ReceiptTable';
import ReceiptSumTable from '../components/ReceiptSumTable';
import LoadingView from './LoadingView';
import {
  useCardPaymentStore,
  useCartStore,
  usePaymentStore,
  useReceiptStore,
  useShopStore,
} from '../stores';
import isColorDark from '../utils/isColorDark';

const PaymentFinishedView = observer(() => {
  const receiptStore = useReceiptStore();
  const shopStore = useShopStore();
  const paymentStore = usePaymentStore();

  const cardPaymentStore = useCardPaymentStore();
  const cartStore = useCartStore();
  const [email, setEmail] = useState(
    paymentStore.email ? paymentStore.email : ''
  );
  const [emailError, setEmailError] = useState('');
  const [sending, setSending] = useState(false);
  const [emailSent, setEmailSent] = useState(!!paymentStore.email);
  const [loading, setLoading] = useState(true);
  const cartKey = localStorage.getItem('cartKey');
  const paymentId = localStorage.getItem('paymentId');
  const { t } = useTranslation();

  useEffect(() => {
    if (cartKey && paymentId) {
      receiptStore.loadCart(cartKey, paymentId).finally(() => {
        setLoading(false);
        cardPaymentStore.clear();
        cartStore.clear();
      });
    } else {
      setLoading(false);
    }
  }, [cartKey, paymentId, cardPaymentStore, cartStore, receiptStore]);

  const colorBasedOnBrightnessOfBackGround = isColorDark(
    shopStore.headerTitleColor
  )
    ? 'black'
    : 'white';

  const sendReceipt = () => {
    if (sending) {
      return;
    }

    if (!email || !email.match(/^.+@.+\..+$/)) {
      setEmailError(t('payment.checkEmail'));
      return;
    }
    setEmailError('');
    setSending(true);

    receiptStore
      .sendReceipt(email)
      .then(() => {
        setEmailSent(true);
      })
      .catch(() => {
        setEmailError(t('payment.faildToSendReceipt'));
      })
      .finally(() => {
        setSending(false);
      });
  };

  const renderReceiptSent = () => (
    <p>{t('payment.receiptSentTo', { email })}</p>
  );

  const renderEmailInput = () => (
    <>
      <div className="form-group">
        <label>{t('payment.yourEmail')}:</label>
        <input
          type="email"
          autoComplete="email"
          id="email"
          className={`form-control ${emailError ? 'is-invalid' : ''}`}
          value={email}
          onChange={(evt) => {
            setEmail(evt.target.value);
          }}
        />
        {emailError && (
          <small className="form-text text-danger">{emailError}</small>
        )}
      </div>
      <button
        type="button"
        className="btn btn-block mb-3"
        style={{
          backgroundColor: shopStore.headerTitleColor,
          border: 'none',
          color: colorBasedOnBrightnessOfBackGround,
        }}
        onClick={sendReceipt}
        disabled={sending}
      >
        {sending ? (
          <div className="spinner-border spinner-border-sm text-light" />
        ) : (
          t('payment.sendReceipt')
        )}
      </button>
    </>
  );

  const renderSuccess = () => (
    <>
      <div className="row payment-finished-pickup mb-3 mt-1">
        <div className="col">
          {!shopStore.hasPacklist
            ? t('pickup.pickupProducts')
            : `${t('pickup.pickupOrder')} `}
          {shopStore.hasPacklist && (
            <span className="badge badge-primary payment-finished-orderid">
              #{receiptStore.orderId?.toString().padStart(4, '0') || ''}
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col mt-3">
          <div className="payment-receipt">
            <div className="row">
              <div className="col payment-receipt-date">
                {receiptStore.paymentDate.split('T')[0]}
              </div>
            </div>
            <div className="row">
              <div className="col payment-receipt-title">
                {t('payment.receipt')}
              </div>
            </div>
            <div className="payment-receipt-separator" />
            <div className="row">
              <div className="col pt-3">
                <ReceiptTable receiptStore={receiptStore} />
              </div>
            </div>
            <div className="payment-receipt-separator" />
            <div className="row">
              <div className="col pt-3">
                <ReceiptSumTable receiptStore={receiptStore} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          {emailSent || receiptStore.cutomerEmail
            ? renderReceiptSent()
            : renderEmailInput()}
        </div>
      </div>
    </>
  );

  if (receiptStore.isLoading || loading) {
    return <LoadingView text={t('payment.completingPurchase')} />;
  }

  return (
    <PageContainer title={t('payment.purchaseComplete')} hideCartLine>
      <div className="col col-xs-12 col-md-8 col-lg-6 mx-auto">
        {renderSuccess()}

        <div className="row">
          <div className="col mt-4 pb-5">
            <Link
              to={`/${shopStore.id}`}
              className="btn btn-block"
              style={{
                backgroundColor: shopStore.headerTitleColor,
                border: 'none',
                color: colorBasedOnBrightnessOfBackGround,
              }}
              onClick={() => {
                localStorage.removeItem('cartKey');
                localStorage.removeItem('paymentId');
              }}
            >
              <i
                className="far fa-times-circle"
                style={{ color: colorBasedOnBrightnessOfBackGround }}
              />
              {t('payment.complete')}
            </Link>
          </div>
        </div>
      </div>
    </PageContainer>
  );
});

export default PaymentFinishedView;
