import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useCartStore } from '../stores';

const CartSumTable = observer(() => {
  const cartStore = useCartStore();
  const { t } = useTranslation();

  const renderVatLines = () => {
    const { vatSums } = cartStore;
    const sortedKeys = Object.keys(vatSums).sort((a, b) =>
      a.toString().padStart(2, '0') < b.toString().padStart(2, '0') ? -1 : 1
    );

    const vatTotal = Object.values(vatSums).reduce((val, acc) => {
      return val.add(acc);
    });

    const headerRow = (
      <tr key="moms">
        <td>{t('cart.vat')}:</td>
        <td>{vatTotal.format()}</td>
      </tr>
    );

    return [headerRow].concat(
      sortedKeys.map((vatKey) => (
        <tr key={vatKey}>
          <td className="cart-tax-cell">
            {t('cart.whereOf')} {vatKey}
            %:
          </td>
          <td>{vatSums[vatKey].format()}</td>
        </tr>
      ))
    );
  };

  return (
    <table className="table table-sm cart-sum-table">
      <tbody>
        {renderVatLines()}
        <tr>
          <td>{t('cart.sumExVat')}:</td>
          <td>{cartStore.sumNoVat.format()}</td>
        </tr>
        <tr className="cart-sum-row">
          <td>{t('cart.sum')}</td>
          <td>{cartStore.sum.format()}</td>
        </tr>
      </tbody>
    </table>
  );
});

export default CartSumTable;
