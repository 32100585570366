import { observer } from 'mobx-react';
import React from 'react';
import { useShopStore } from '../stores';
import isColorDark from '../utils/isColorDark';
import { getTag } from '../utils/product';

const ProductTags = observer(({ articleTags }) => {
  const shopStore = useShopStore();
  const { tags } = shopStore;
  return (
    <div className="productTagContainer">
      {articleTags.map((tag) => (
        <p
          className="productTag"
          style={{
            backgroundColor: getTag(tag, tags).color,
            color: isColorDark(getTag(tag, tags).color) ? 'black' : 'white',
          }}
        >
          {getTag(tag, tags).tag}
        </p>
      ))}
    </div>
  );
});

export default ProductTags;
