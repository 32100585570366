import { observer } from 'mobx-react';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthStore } from '../stores';
import LoadingView from '../views/LoadingView';

const AuthenticatedRoute = observer(({ ...props }) => {
  const auth = useAuthStore();
  const { path } = props;

  if (auth.isLoading) {
    return <LoadingView />;
  }

  if (!auth.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: path },
        }}
      />
    );
  }

  /* eslint-disable react/jsx-props-no-spreading */
  return <Route {...props} />;
});

export default AuthenticatedRoute;
