import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingView from '../views/LoadingView';

function LoadingRoute({ component, loaded, ...restOfProps }) {
  const { t } = useTranslation();
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Route
      {...restOfProps}
      render={() =>
        loaded ? component : <LoadingView text={t('common.loading')} />
      }
    />
  );
}

export default LoadingRoute;
